@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  &__topSec {
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;

    &__tabs {
      button {
        width: 100%;
      }
    }

    &__heading {
      color: var(--base-heading-color) !important;
      font-size: 20px !important;
      font-weight: bold !important;
    }

    &__search {
      background: var(--base-inner-container-background-color) !important;
      border-radius: 12px;
      position: relative;
      height: 42px;
      &__input {
        // position: relative;
        width: 100%;
        outline: none;
        border: none;
        padding: 13px 20px;
        background: transparent;
        color: var(--base-input-text-color);
        border-radius: 12px;
        border: none;
        font-size: 14px;
        margin-left: 10px;
        &::placeholder {
          color: var(--base-heading-color);
          // opacity: 0.5;
        }
        &__icon {
          position: absolute;
          top: 12px;
          right: 20px;
          svg {
            fill: var(--base-icon-color);
          }
        }
        &__loading {
          position: absolute;
          top: 10px;
          right: 50px;
          &__loader {
            width: 100%;
            path {
              stroke: var(--base-tags-color) !important;
            }
          }
        }
      }
    }
    &__result {
      position: absolute;
      width: 100%;
      height: 350px;
      background-color: var(--base-inner-container-background-color);
      border: 1px solid var(--base-img-border-color);
      border-radius: 12px;
      padding: 20px 20px 80px 20px;
      color: var(--base-paragraph-color);
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      z-index: 10 !important;
      color: var(--base-paragraph-color);
      margin-top: -10px;
      &::-webkit-scrollbar {
        display: none;
      }
      &__found {
        font-size: 14px !important;
        font-weight: bold !important;
        color: var(--base-tags-color);
      }
      &__no_result {
        color: var(--base-tags-color);
        font-size: 12px !important;
        font-weight: 500 !important;
        text-transform: capitalize;
      }
      &__array_length {
        margin-top: 10px !important;
        font-size: 18px !important;
        font-weight: bold !important;
        color: var(--base-paragraph-color);
      }
      &__details {
        display: flex;
        gap: 20px;
        align-items: center;
        margin: 5px 0px;
        padding: 10px;
        cursor: pointer;
        &__avatar {
          border: 2px solid var(--picture-border-color);
          width: 45px !important;
          height: 45px !important;
          border-radius: 50%;
        }
        &__name {
          font-size: 16px !important;
          font-weight: bold !important;
          white-space: nowrap;
          width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__country_flag {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 12px !important;
          margin-top: 2px;
        }
        &:hover {
          background-color: var(--base-hover-list-color);
          border-radius: 12px;
        }
        &__divider {
          border-bottom: 1px solid var(--base-border-line-color);
        }
      }
      &__add_btn {
        position: relative;
        margin: 0px 20px;
        z-index: 99;
        &__btn {
          background-color: var(--base-inner-container-background-color);
          position: absolute;
          padding: 16px 0px 4.5px 0px;
          width: 100%;
          top: 260px;
          overflow: hidden !important;
          button {
            background-color: var(--base-button-background-color);
            color: var(--base-button-text-color);
            font-size: 14px !important;
            font-weight: bold;
            width: 100%;
            border-radius: 12px !important;
            &:hover {
              background-color: var(--base-button-background-color);
              color: var(--base-button-text-color);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .page {
    &__topSec {
      &__tabs_container {
        button {
          white-space: nowrap;
          font-size: 11px !important;
        }
      }
    }
  }
}
