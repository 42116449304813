@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  &__letStart {
    background-image: var(--base-let-start-container-gradient);
    height: 71px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    &__heading {
      color: #fff !important;
      font-size: 24px !important;
      font-weight: bold !important;
    }
    &__icon {
      background-color: var(--base-heading-color) !important;
      &__rightIcon {
        font-size: 15px !important;
        fill: var(--base-action-icon-color);
      }
    }
    &__divider {
      border-top: 1px solid var(--base-border-line-color) !important;
      height: 2px !important;
    }
  }
  &__customPanel {
    font-family: "DM Sans";
    width: 100% !important;
    height: 100% !important;
    background: var(--base-container-backgroung-color) !important;
    color: var(--base-heading-color) !important;
    border-radius: 12px;
    border: 1px solid var(--base-border-line-color) !important;
    &__heading {
      color: var(--base-heading-color) !important;
      font-weight: bold !important;
      font-size: 24px !important;
    }
    &__contractContainer {
      &__contractList {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__list {
          width: 100%;
          background-color: var(--base-inner-container-background-color);
          border-radius: 12px;
          padding: 20px 30px;
          &__listContent {
            display: flex;
            justify-content: space-between;
            &__heading {
              font-size: 24px !important;
              color: #fff !important;
              font-weight: bold !important;
              &:hover {
                color: var(--base-tags-color) !important;
              }
            }
            &__listContentIcon {
              background-color: var(--base-action-icon-background) !important;
              color: var(--base-action-icon-color) !important;
              width: 30px;
              height: 30px;
              &:hover {
                background-color: var(--base-action-icon-background) !important;
                color: var(--base-action-icon-color) !important;
              }
            }
          }
        }
      }
    }
  }
}

.onBoard {
  @include activeThemeBotton();
  width: 30% !important;
}
