@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  color: var(--base-heading-color) !important;
  font-family: "DM Sans";

  &__pageTitle {
    @include customHeading(24px);
  }

  &__title_bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      font-weight: 700 !important;
    }
  }

  &__fieldsContainer {
    margin-top: 20px;
    background: var(--base-container-backgroung-color);
    border-radius: 12px;
    border: 1px solid var(--base-border-line-color);
    height: 600px !important;

    &__image_container {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 40px;

      &__avatar_container {
        position: relative;
        &__avatar {
          height: 89px !important;
          width: 89px !important;
          border: 4px solid var(--base-img-border-color);
        }
        &__icon {
          position: absolute;
          top: 30px;
          left: 30px;
        }
      }
    }

    &__title {
      @include customHeading();
    }

    &__form {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100% !important;
      gap: 30px !important;
      margin-top: 30px;
      margin-bottom: 30px;

      &__formfield {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        gap: 10px;

        &__label {
          margin-left: 10px;
          @include customTags();
          &__image {
            height: 16px;
            width: 16px;
          }
        }

        &__input {
          width: 100%;

          // color: var(--base-input-text-color) !important;
          // border: 1px solid var(--base-input-border-color) !important;
          // background: var(--base-input-background-color);
          // outline: none;
          // padding: 15px;
          // font-size: 16px;
          // border-radius: 12px;
          // width: 100%;
          // height: 48px !important;
        }

        &__phoneinput {
          color: var(--base-input-text-color) !important;
          background: var(--base-input-background-color);
          padding: 15px;
          font-size: 18px;
          border-radius: 12px;
          width: 100%;
          height: 48px !important;
          input {
            color: var(--base-input-text-color) !important;
            background: transparent;
            outline: none;
            font-size: 16px;
            border: none;
          }
        }

        &__select_icon {
          color: var(--base-icon-color) !important;
          font-size: 20px;
          font-weight: bold;
          margin-right: 15px;
          cursor: pointer;
        }
      }

      &__action {
        gap: 20px;
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        // width: 100% !important;
        margin-top: 20px;
        &__cancel_btn {
          @include cancelDeactiveBtn(16px, 10px);
          height: 48px !important;
          padding: 5px 10px !important;
        }

        &__submit_btn {
          @include activeThemeBotton();
          height: 48px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__fieldsContainer {
      height: 100% !important;
    }
  }
}
