@import "../../styles/scss.scss";
@import "../../styles/colors.scss";
.page {
  width: 100%;
  background-color: var(--base-container-backgroung-color) !important;
  box-shadow: none !important;
  overflow: hidden !important;
  padding: 10px 20px;
  &__placement {
    @include customHeading(16px);
    @include textEllipsis(150px);
    cursor: pointer;
    :hover {
      color: var(--base-hover-color);
    }
    &__avatar {
      height: 45px !important;
      width: 45px !important;
    }
  }
}
.table {
  &__row {
    &__genre {
      display: flex;
      font-size: 10px !important;
      gap: 5px;
      &__chip {
        background-color: var(--base-container-backgroung-color) !important;
        color: var(--base-heading-color) !important;
        padding: 15px 5px !important;
        cursor: pointer !important;
      }
    }

    &__green {
      color: #008000;
    }

    &__red {
      color: #ff1e1e;
    }

    &__icon {
      cursor: pointer;
    }

    &__name {
      display: flex;
      width: 200px;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .actionEdit {
    background-color: var(--base-button-background-color);
    width: 33px;
    height: 33px;
    &:hover {
      background-color: var(--base-button-background-color);
    }
    svg {
      fill: var(--base-action-icon-color);
    }
  }
  .actionDelete {
    background-color: var(--base-action-icon-delete-background);
    width: 33px;
    height: 33px;
    &:hover {
      background-color: var(--base-action-icon-delete-background);
    }
    svg {
      fill: var(--base-action-icon-color);
    }
  }
}

.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-title-font-color);
    font-size: 20px;
    font-weight: bold;
    background-color: var(--base-title-bg-color);
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: var(--base-container-backgroung-color) !important;
        color: var(--base-icon-color) !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: var(--base-icon-color);
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
