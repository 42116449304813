// ? new csss
@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.tabDisplay {
  display: block;
}

.tabHide {
  display: none;
}

.page {
  // @include flexProperties(row, center, flex-start, $fullWidth: true);
  font-family: "DM Sans" !important;

  background: var(--base-container-backgroung-color);
  border-radius: 12px;
  &__artist {
    flex: 1;
    width: 100%;

    &__box {
      height: 100%;
      width: 100%;
      &__rightBox {
        height: calc(100% + 3px);
        width: 100%;
      }
      &__editbtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        z-index: 1;
        padding: 15px 0px 0px 0px !important;
        &__icon {
          padding: 15px !important;
        }
        &__btnICon {
          display: none !important;
          box-shadow: 0px 5px 26px 36px rgba(250, 16, 45, 0.61);
          -webkit-box-shadow: 0px 5px 26px 36px rgba(250, 16, 45, 0.61);
          -moz-box-shadow: 0px 5px 26px 36px rgba(250, 16, 45, 0.61);
        }
      }

      &__topdetails {
        padding: 30px 20px;
        width: 100%;
        height: 139px;

        &__image {
          .onlineStatus {
            position: relative;
            display: inline-block;
            .avatar {
              width: 89px !important;
              height: 89px !important;
              border: 4px solid var(--base-button-background-color) !important;
              object-fit: cover;
            }
            .onlineCircle {
              position: absolute;
              bottom: 15px;
              right: 0;
              width: 16px;
              height: 16px;
              background-color: var(--base-button-background-color);
              border-radius: 50%;
            }
          }
          .skeleton {
            background-color: grey;
            opacity: 0.7;
            height: 89px;
            width: 89px;
          }
        }

        &__details {
          font-family: "DM Sans";
          color: var(--base-heading-color);

          width: 100%;
          &__artistname {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100% !important;
            &__name {
              @include customHeading(1.2rem);
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 180px;
            }
            &__icon_container {
              margin-top: 0.5 !important;
              cursor: pointer !important;
              background-color: #4ffcb7 !important;
              height: 30px !important;
              width: 30px !important;
              &:hover {
                background-color: #4ffcb7;
              }
            }
          }
          &__email {
            @include customParagraph(14px);
            text-decoration: underline;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 180px;
          }
          &__country {
            font-size: 14px;
            &__name {
              margin-left: 5px;
              @include customParagraph(14px);
            }
          }
        }
      }

      &__horizontalline {
        width: calc(100%) !important;
        height: 1px !important;
        background-color: var(--base-border-line-color);
      }

      &__listnership {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: var(--base-heading-color);
        padding: 20px !important;
        height: 102px;
      }

      &__platforms {
        width: 100%;
        padding: 20px;
        height: 102px;

        &__socials {
          margin-top: 8px;
        }
        &__verify {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__genre {
        width: 100%;
        height: 93px;
      }

      &__tracks_info {
        width: 100%;
        gap: 20px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        height: 105px;
        &__container {
          align-items: center;
          justify-content: center;

          &__text2 {
            color: var(--base-heading-color);
            font-size: 28px !important;
            font-weight: 700 !important;
          }
        }
        &__containerright {
          color: var(--base-heading-color);

          &__text {
            font-size: 12px !important;
            padding-bottom: 2px;
          }
        }
      }

      &__buttons_container {
        padding: 20px !important;
        button {
          @include activeThemeBotton();
          &:hover {
            @include activeThemeBotton();
          }
        }
      }
      &__buttons_containers {
        padding: 15px 20px 20px 20px !important;

        &__fundingBtn {
          @include activeThemeBotton();
          opacity: 50%;
          padding: 7px 10px !important;
          img {
            width: 15px;
            margin-right: 6px;
          }
        }
        &__editDash {
          @include deActiveThemeBotton();
          margin-top: 10px !important;
        }
      }
      &__albumList {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
        padding: 20px;
      }
      &__endBox {
        &__last {
          width: 100%;
          height: calc(100% - 136px);
        }
      }
      &__smallHeading {
        @include customTags();
      }
      &__fontSize {
        @include customHeading(28px);
        font-weight: bold !important;
      }
      &__drop {
        display: flex;
        gap: 20px;
        align-items: center;
        position: relative;

        &__shape {
          position: absolute;
          width: 222px;
          height: 243.33px;
          padding: 20px;
          border: 2px solid #5a7380;
          background: #192233;
          border-radius: 0 12px 12px 12px;
          margin-top: 20px;
          margin-left: 20px;
          &::before {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-bottom: 30px solid #192233;
            border-right: 30px solid transparent;
            left: 0px;
            top: 0px;
            margin-top: -15px;
            z-index: 22;
          }
          &::after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-bottom: 20px solid #5a7380 !important;
            border-right: 20px solid transparent;
            left: -2px;
            top: 0px;
            margin-top: -20px;
          }
        }
        &__worldList {
          &__list {
            gap: 20px;
            height: 180px;
            overflow-y: scroll;
            -ms-overflow-style: none !important; /* IE and Edge */
            scrollbar-width: none !important;
            &__items {
              margin-top: 15px;
              cursor: pointer;
              display: flex;
              gap: 12px;
              align-items: center;
              &__flag {
                font-size: 40px;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }
              &__text {
                margin-top: -4px;
                font-size: 16px !important;
                color: white !important;
                font-weight: 500 !important;
              }
            }
          }
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: false);
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: var(--base-heading-color);
            font-size: 24px;
            font-weight: 700;
            text-transform: capitalize;
          }

          &__btn {
            background: var(--base-button-background-color) !important;
            color: var(--base-button-background-color) !important;
            border-radius: 10px !important;
            font-size: 16px !important;
            cursor: pointer;
            text-transform: capitalize !important;
            width: 139px;
            height: 40px;
            border: none;
            padding: 5px 20px !important;
            font-family: "DM Sans" !important;

            &:hover {
              background: var(--base-button-background-color) !important;
              border-color: var(--base-button-background-color) !important;
            }
          }
        }

        &__content {
          @include flexProperties(
            row,
            space-between,
            center,
            $fullWidth: false
          );
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 40px;

          &__box {
            @include flexProperties(row, flex-start, center, $fullWidth: false);
            flex-wrap: wrap;
            gap: 10px;
            width: 45%;

            &__title {
              color: var(--base-button-background-color);
              font-size: 16px;

              &__image {
                height: 20px;
                width: 20px;
                cursor: pointer;
              }
            }
          }
        }
      }

      &__tracks {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: var(--base-button-background-color);
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &__btn {
            background: var(--base-button-background-color) !important;
            color: var(--base-button-background-color) !important;
            border-radius: 25px !important;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            border: none;
            padding: 5px 20px !important;
            font-family: "DM Sans" !important;

            &:hover {
              background: var(--base-button-background-color) !important;
            }
          }
        }
      }
    }
  }

  &__similar {
    flex: 1;
    height: 100%;
    width: 100%;

    &__box {
      background: var(--base-container-backgroung-color);
      height: 100%;
    }
  }
  .contract {
    margin-top: 2px;
    position: relative;
    z-index: 9;
    .initiate {
      display: flex;

      .btn1 {
        border-radius: 12px 0px 0px 12px;
        padding: 5.5px 20px 5.5px 40px !important;
        font-size: 14px;
        background-color: var(--base-button-background-color);
        font-weight: bold;
        color: var(--base-container-backgroung-color);
        border: 2px solid var(--base-button-background-color);
        height: 40px;
      }

      .btn2 {
        border-radius: 0px 12px 12px 0px;
        padding: 8.3px 13px !important;
        border-left: 2px solid var(--base-container-backgroung-color);
        font-size: 20px;
        font-weight: 700 !important;
        background-color: var(--base-button-background-color);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 40px;
      }
      .transparentBtn {
        background-color: transparent;
        color: var(--base-button-background-color);
      }
      .transparentBtn2 {
        border: 2px solid var(--base-button-background-color);
        border-left: none;
        background-color: transparent;
        color: var(--base-button-background-color);
      }
    }
    .contractDetails {
      position: absolute;
      top: 60px;
      bottom: 0px;
      z-index: 1;
      .mainContainer {
        width: 350px;
        height: 300px;
        border: 2px solid var(--base-button-background-color);
        border-radius: 12px;
        background-color: var(--base-inner-container-background-color);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        .chipStyle {
          width: 100%;
          display: flex;
          padding: 14px;
          font-size: 14px;
          font-weight: bold;
          text-transform: capitalize;
          border-radius: 12px;
          justify-content: center;
          background-color: var(--base-border-line-color);
          color: var(--base-heading-color);
          cursor: pointer;
        }
      }
    }
  }
  .updateArtist {
    background: transparent !important;
    // background: var(--base-button-background-color) !important;
    border: 2px solid var(--base-button-background-color) !important;
    color: var(--base-button-background-color) !important;
    border-radius: 12px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    width: 100%;
    border: none;
    white-space: nowrap;
    height: 40px !important;
  }
  .blankHeight {
    height: 139px !important;
  }
  .EditDataBox {
    display: flex;
    flex-direction: column;
    gap: 35px;

    button {
      @include activeThemeBotton();
      width: auto !important;
      padding: 7px 10px !important;
      box-shadow: 0px 5px 26px -4px rgba(0, 0, 0, 0.61);
      -webkit-box-shadow: 0px 5px 26px -4px rgba(0, 0, 0, 0.61);
      -moz-box-shadow: 0px 5px 26px -4px rgba(0, 0, 0, 0.61);
    }
  }
  .fundingDash {
    height: 139px;
    color: var(--base-heading-color);
    display: flex;
    justify-content: flex-start;
    align-items: start;
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    white-space: nowrap;
    position: relative;
    width: 100%;
    div {
      position: absolute;
      top: 25px;
      left: 20px;
      z-index: 1;
    }
  }
  .newTracksText {
    color: var(--base-heading-color) !important;
    padding-top: 10px !important;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    height: 100% !important;
    &__artist {
      flex: 1;
      width: 100%;
      &__box {
        height: 100%;
        &__rightBox {
          border: none !important;
        }
        &__endBox {
          width: 100%;
          &__last {
            border-radius: 0px 0px 12px 12px !important;
            border-left: none !important;
            height: calc(100% - 139px) !important;
            padding-bottom: 20px;
          }
        }
        &__topdetails {
          &__details {
            &__email {
              width: 100% !important;
            }
            &__artistname {
              &__name {
                width: 100% !important;
              }
            }
          }
        }
        .EditDataBox {
          margin-left: 40px;
        }
      }
    }
    .blankHeight {
      // height: 10px !important;
      button {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .page {
    &__artist {
      &__box {
        &__rightBoxCss {
          gap: 10px !important;
          // flex-wrap: wrap-reverse;
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__image {
            .onlineStatus {
              .avatar {
                width: 80px !important;
                height: 80px !important;
              }
              .onlineCircle {
                bottom: 10px;
              }
            }
            .skeleton {
              width: 80px !important;
              height: 80px !important;
            }
          }
          &__details {
            &__artistname {
              font-size: 20px !important;
            }
          }
        }
      }
    }
    .page__artist__box__topdetails__details__email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 150px;
    }
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        // min-height: 500px;
        &__buttons_containers {
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .page {
    &__artist {
      &__box {
        .onlineStatus {
          .avatar {
            width: 70px !important;
            height: 70px !important;
          }
          .onlineCircle {
            bottom: 8px;
          }
        }
        .skeleton {
          width: 70px !important;
          height: 70px !important;
        }
      }
    }
  }
  .page__artist__box__topdetails__details__artistname__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100px;
  }
}

@media only screen and (max-width: 1300px) {
  .page {
    &__artist {
      &__box {
        &__listnership {
          &__innerlyer {
            &__text2 {
              font-size: 25px !important;
            }
          }
        }

        &__albumList {
          gap: 10px !important;
        }
      }
    }
  }

  .EditDataBox {
    button {
      padding: 5px 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .page {
    margin-left: 10px;
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              font-size: 16px !important;
            }
            &__email {
              font-size: 12px;
            }
          }
        }
        &__rightBoxCss {
          gap: 10px !important;
          flex-wrap: wrap-reverse;
        }
        .EditDataBox {
          margin-left: 0px;
        }

        .updateArtist {
          width: 100%;
          // font-size: 10px;
          white-space: nowrap;
        }
        &__tracks_info {
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          // padding: 10px !important;
          // margin: 0 !important;
          height: 150px;
          &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .fundingDash {
          font-size: 20px !important;
          height: 90px;
        }
        .skeleton {
          width: 50px !important;
          height: 50px !important;
        }

        .onlineStatus {
          .avatar {
            width: 50px !important;
            height: 50px !important;
          }
          .onlineCircle {
            width: 15px;
            height: 15px;
            bottom: 5px;
          }
        }
        &__endBox {
          &__last {
            height: calc(100%);
          }
        }
      }
    }
    .contract {
      .initiate {
        .btn1 {
          font-size: 10px;
        }
      }
      .contractDetails {
        .mainContainer {
          width: 100%;

          .chipStyle {
            font-size: 10px;
            padding: 15px 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__image {
            .onlineStatus {
              .avatar {
                width: 70px !important;
                height: 70px !important;
              }
              .onlineCircle {
                bottom: 10px;
                right: 0;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        &__rightBoxCss {
          gap: 40px !important;
        }
      }
      .skeleton {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }
  .page__artist__box__topdetails__details__artistname__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 150px;
  }
}
