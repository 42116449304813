.page {
  &__artist {
    &__box {
      &__platforms {
        &__socials {
          width: 100%;
          display: flex;
          gap: 7px;
          margin-top: 8px !important;
          // display: none;
          &__icons {
            width: 26px;
            height: 26px;
            // margin: auto;
            display: block;
          }
          &__chip {
            color: white !important;
            background-color: #5a7280 !important;
            cursor: pointer;
            font-size: 12px !important;
          }
          // &__bigScreen {
          //   width: 100%;
          //   display: flex;
          //   gap: 7px;
          //   margin-top: 8px;
          //   img {
          //     width: 26px;
          //     height: 26px;
          //   }
          // }
        }
      }
    }
  }
}
.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-title-font-color);
    font-size: 20px;
    font-weight: bold;
    background-color: var(--base-title-bg-color);
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: #222c41 !important;
        color: var(--base-title-bg-color) !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: var(--base-title-bg-color);
        }
      }
    }
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      background-color: #222c41;
      border-radius: 12px;
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}
@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
@media only screen and (max-width: 1600px) {
  .page {
    &__artist {
      &__box {
        &__platforms {
          &__socials {
            // display: flex;

            &__bigScreen {
              // display: none;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .page {
    &__artist {
      &__box {
        &__platforms {
          &__socials {
            // display: none;

            &__bigScreen {
              // display: flex;
            }
          }
        }
      }
    }
  }
}
