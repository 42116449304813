@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__banner {
    background: var(--base-container-backgroung-color);
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    height: 370px;
    max-height: 370px;
    overflow-y: hidden;
    border: 1px solid var(--base-border-line-color);

    &__topheading {
      @include customHeading(20px);
    }

    &__conatiner {
      @include flexProperties(column, center, center, $fullWidth: true);
      gap: 20px;
      width: 100%;

      &__bg {
        width: 100%;
        height: 100px;
        overflow: hidden;
      }
    }
  }

  &__container {
    min-height: 280px !important;
    max-height: 280px !important;
    margin: 20px 0px 0px 0px !important;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__loader_container {
    min-height: 280px !important;
    max-height: 280px !important;
    margin: 20px 0px 0px 0px !important;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__main_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--base-inner-container-background-color);
    margin-bottom: 10px !important;
    padding: 10px !important;
    border-radius: 12px !important;
    align-items: center;
    &__rounded_image {
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 160%;
        object-fit: cover;
      }
    }
    &__platform_content_container {
      width: 100%;
      margin-left: 20px !important;
      color: #fff;
      &__top_heading {
        @include customHeading(16px);
      }
      &__platform_streaming_container {
        @include flexProperties(row, flex-start, center, $fullWidth: true);
        width: 100% !important;
        &__logo_stream {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          margin-right: 10px !important;
          margin-top: 10px !important;
        }
        &__text {
          @include customParagraph(12px);
        }
      }
    }
  }

  &__countryTopHeading {
    @include customHeading(18px !important);
    font-size: 18px !important;
    font-weight: 700 !important;
  }
  &__countrySubHeading {
    @include customParagraph(12px);
  }
  &__countrySubHeading2 {
    @include customParagraph(10px);
    // margin-top: 2px !important;
    // margin-left: 5px !important;
  }
}

@media only screen and (max-width: 1425px) {
  .page {
    &__statusBar {
      width: 100%;
    }

    &__banner {
      width: 100%;
    }

    &__main_content {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1267px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__details {
            &__box {
              &__title {
                font-size: 18px;
              }

              &__value {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 944px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__details {
            &__box {
              &__title {
                font-size: 16px;
              }

              &__value {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__statusBar {
      display: none;
    }

    &__banner {
      &__conatiner {
        flex-direction: column;

        &__image {
          @include flexProperties(row, center, center, $fullWidth: false);
          width: 100%;
        }

        &__content {
          @include flexProperties(column, center, center, $fullWidth: false);
        }
      }
    }
  }
}

@media only screen and (max-width: 677px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__details {
            &__box {
              &__title {
                font-size: 18px;
              }

              &__value {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 582px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 30px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 559px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 28px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 559px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 26px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 24px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 22px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 388px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 18px !important;
            letter-spacing: 3px;
          }

          &__details {
            &__box {
              &__title {
                font-size: 16px;
              }

              &__value {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 18px !important;
            letter-spacing: 2px;
          }

          &__details {
            &__box {
              &__title {
                font-size: 12px;
              }

              &__value {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 318px) {
  .page {
    &__banner {
      &__conatiner {
        &__content {
          &__main_heading {
            font-size: 16px !important;
            letter-spacing: 0px;
          }

          &__details {
            &__box {
              &__title {
                font-size: 10px;
              }

              &__value {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
