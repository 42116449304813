.page {
  &__tabs_container {
    display: flex;
    gap: 2px;
    border-radius: 15px !important;
    z-index: 10;
    // width: 100%;
    // padding: 8px 10px;

    button {
      padding: 8px 20px;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      border: none;
      white-space: nowrap;
    }
    button:nth-child(1) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 15px 0px 0px 15px;
      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
    button:nth-child(2) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 0px;
      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
    button:nth-child(3) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 0px;
      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
    button:nth-child(4) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 0px 15px 15px 0px;
      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
    // &__tabActive {
    //   background-color: var(--base-button-background-color) !important;
    // }
  }
}
