@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  gap: 0px;
  width: 100%;
  font-family: "DM Sans" !important;
  &__topHeadHeadings {
    @include customTags();
  }
  &__heading1 {
    @include customHeading(16px);
    text-decoration: underline;
  }

  &__heading2 {
    @include customHeading(16px);
  }
}
