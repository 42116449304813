@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__container {
    background: var(--base-container-backgroung-color);
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    height: 370px;
    max-height: 370px;
    overflow-y: hidden;
    border: 1px solid var(--base-border-line-color);
    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      &__heading {
        @include customHeading(20px);
      }
    }

    &__innerContainer {
      @include flexProperties(row, center, flex-start, $fullWidth: true);
      height: 100%;
    }
  }
}
