@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;
        height: 675px !important;
        max-height: 675px !important;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            @include customHeading(24px);
          }
          &__textinput_container {
            width: 255px;
            height: 41px;
            background-color: var(--base-input-text-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            &__input {
              width: 100%;
              height: 100%;
              background-color: transparent;
              border-style: none;
              font-size: 14px !important;
              color: var(--base-input-text-color);
              &__icon_color {
                // color: var(--base-icon-color)s !important;
              }
              &:focus {
                border-style: none !important;
                outline: none !important;
              }
              &:active {
                border-style: none !important;
                outline: none !important;
              }

              &::placeholder {
                color: var(--base-input-text-color);
                opacity: 0.3;
              }
            }
          }
        }

        &__main_content {
          @include flexProperties(column, center, center, $fullWidth: false);
          width: 100% !important;
          height: 95% !important;
          overflow: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1700px) {
    .page {
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 684px) {
    .page {
      &__artist {
        flex: 1;

        &__box {
          min-height: 500px;
        }
      }
    }
  }

  @media only screen and (max-width: 434px) {
    .page {
      &__details {
        &__box {
          &__adetails {
            &__content {
              @include flexProperties(
                column,
                flex-start,
                flex-start,
                $fullWidth: false
              );
              gap: 20px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 630px) and (max-width: 1199.98px) {
    .page {
      flex-direction: column;
      align-items: center;
    }
  }
}
