@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../assets/login_bg.png);
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  font-family: "DM Sans";
  overflow: hidden;
  align-items: center;
  justify-content: center;

  &__form_page_container {
    @include flexProperties(column, center, center, $fullWidth: true);

    &__login {
      width: 30.83% !important;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      display: flex;
    }

    &__loginContant {
      width: 30.83% !important;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      display: flex;
      height: 52px !important;
      background: rgba(0, 3, 54, 0.1);
      backdrop-filter: blur(10px);
      margin-top: 40px;
      gap: 10px;

      &__text {
        @include customParagraph();
      }
    }

    &__details_container {
      @include flexProperties(row, center, center, $fullWidth: false);
      text-align: center;
      gap: 20px;
      margin-top: 40px;
      @include customTags();
      text-transform: capitalize !important;
      cursor: pointer;
    }
    &__ownership_container {
      @include flexProperties(row, center, center, $fullWidth: false);
      text-align: center;
      gap: 20px;
      margin-top: 40px;
      @include customTags();
      color: #ffffff !important;
      text-transform: capitalize !important;
    }
  }

  // Define styles for dark theme
  &.dark {
    background-color: #333;
    color: #fff;
    // Customize dark theme styles here
  }

  // Define styles for light theme
  &.light {
    background-color: #fff;
    color: #333;
    // Customize light theme styles here
  }
}

@media only screen and (max-width: 991px) {
  .page {
    font-family: "DM Sans";

    &__login {
      &__info {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .page {
    &__form_page_container {
      &__login {
        width: 60% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__form_page_container {
      &__login {
        width: 90% !important;
      }
      &__loginContant {
        width: 90% !important;
      }
    }
  }
}
