.pageContainer {
  &__button_content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    &__user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      &__name {
        text-transform: capitalize;
        font-size: 22px;
        color: #f3ebea;
      }
      &__role {
        font-size: 18px;
        color: #675d5c;
      }
    }
  }
  &__avatar {
    width: 40px;
    height: 40px;
    border: 2px solid var(--base-img-border-color);
  }
}
