@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, center, $fullWidth: true);
  height: 675px !important;
  max-height: 675px !important;
  width: 100% !important;
  background: var(--base-container-backgroung-color);
  border: 1px solid var(--base-border-line-color);
  padding: 20px;
  border-radius: 12px;
  &__container {
    width: 100% !important;
    height: 600px !important;
    max-height: 600px !important;

    &__topHeader {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      width: 100%;
      margin-bottom: 21px !important;

      &__title {
        @include customHeading(24px);
      }

      &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--base-search-container-background);
        // background: red;
        border-radius: 12px;
        padding: 21px 10px 21px 15px;
        height: 40px;
        &__seaarch_icon {
          color: var(--base-search-container-icon-color);
        }
        &__input {
          background: transparent;
          outline: none;
          color: var(--base-input-text-color);
          border-radius: 12px;
          border: none;
          font-size: 14px;
          margin-left: 10px;
          &::placeholder {
            color: var(--base-heading-color);
            // opacity: 0.5;
          }
        }
      }
    }

    &__list {
      width: 100% !important;
      height: 100% !important;
      &__table {
        height: 95% !important;
        width: 100% !important;
        background: var(--base-container-backgroung-color) !important;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        &__no_contracts {
          @include customHeading();
        }

        &__head {
          background-color: var(--base-container-backgroung-color) !important;
          position: sticky;
          z-index: 9 !important;
        }

        &__brow {
          &__comment_row_container {
            width: 100% !important;
            &__input {
              background-color: var(--base-input-background-color);
              border-radius: 12px;
              padding: 10px;
              border: none !important;
              color: var(--base-input-text-color) !important;
              // border-bottom: 1px none var(--base-input-text-color) !important;
              @include customParagraph(16px);
              color: #ffffff !important;
              padding: 20px !important;
              &:hover {
                border: none !important;
              }
              &::after {
                border: none !important;
              }
            }
            &__button {
              @include activeThemeBotton();
              width: auto !important;
            }
          }
        }

        &__col {
          background: var(--base-container-backgroung-color) !important;
          text-align: left !important;
          width: 50px !important;
          border-bottom: 1px solid var(--base-border-line-color) !important;
          @include textEllipsis();

          &__heading {
            @include customTags();
          }

          &__last {
            background: var(--base-inner-container-background-color) !important;
            text-align: center !important;
          }
          &__lastdays {
            background: var(--base-inner-container-background-color) !important;
            text-align: center !important;
            font-size: 9px;
          }
        }

        &__row {
          border-bottom: 0px !important;
          background-color: var(
            --base-inner-container-background-color
          ) !important;
          @include textEllipsis();

          &__name {
            @include customHeading(24px);
            &__href {
              @include customHeading(24px);
              text-decoration: none;
            }
          }

          &__last_payment {
            @include customParagraph(14px);
          }

          &__created {
            @include customParagraph(14px);
            text-decoration: underline;
          }

          &__created_by {
            @include customParagraph(14px);
            text-decoration: underline;
          }

          &__action {
            &__icons {
              border-radius: 5px;
            }
          }

          &__button:hover {
            background: #4ffcb7 !important;
            color: #000 !important;
          }

          &__artist_image {
            height: 40px !important;
            width: 40px !important;
          }
        }

        &__label {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          gap: 10px;
          @include customTags();
        }

        &__input {
          color: var(--base-input-text-color) !important;
          border: 1px solid #5a7380 !important;
          background: var(--base-input-text-color) !important;
          outline: none;
          padding: 15px;
          font-size: 16px;
          border-radius: 12px;
          width: 100% !important;
          height: 48px !important;
        }

        &__bank_info_wrapper {
          background-color: var(--base-input-background-color);
          padding: 15px;
          border-radius: 12px;
          margin-top: 8px;
          height: 300px;
          overflow-y: auto;
          border: 1px solid #5a7380 !important;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none !important;
          }

          &__name {
            @include customTags();
          }
          &__label {
            @include customParagraph(15px);
            font-weight: bold !important;
          }
          &__input {
            @include customParagraph(14px);
            background-color: #222c41 !important;
            border: none !important;
            width: 100% !important;
            padding: 10px;
            border-radius: 12px;
            &__bank_address {
              background-color: #222c41 !important;

              padding: 10px;
              border-radius: 12px;
              gap: 10px !important;
            }
          }
          &__image {
            width: 150px;
            height: 190px;
            &__downloadbtn {
              @include activeThemeBotton();
            }
          }
          &__box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
          }
          p {
            margin: 0px;
          }
        }
      }
    }
  }
}

// .table {
//   height: 95% !important;
//   width: 100% !important;
//   background: var(--base-container-backgroung-color) !important;
//   overflow: scroll;
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   &__no_contracts {
//     @include customHeading();
//   }

//   &__head {
//     background-color: var(--base-container-backgroung-color) !important;
//     position: sticky;
//     z-index: 9 !important;
//   }

//   &__brow {
//     &__comment_row_container {
//       width: 100% !important;
//       &__input {
//         background-color: var(--base-input-background-color);
//         border-radius: 12px;
//         padding: 10px;
//         border: none !important;
//         color: var(--base-input-text-color) !important;
//         // border-bottom: 1px none var(--base-input-text-color) !important;
//         @include customParagraph(16px);
//         color: #ffffff !important;
//         padding: 20px !important;
//         &:hover {
//           border: none !important;
//         }
//         &::after {
//           border: none !important;
//         }
//       }
//       &__button {
//         @include activeThemeBotton();
//         width: auto !important;
//       }
//     }
//   }

//   &__col {
//     background: var(--base-container-backgroung-color) !important;
//     text-align: left !important;
//     width: 50px !important;
//     border-bottom: 1px solid var(--base-border-line-color) !important;
//     @include textEllipsis();

//     &__heading {
//       @include customTags();
//     }

//     &__last {
//       background: var(--base-inner-container-background-color) !important;
//       text-align: center !important;
//     }
//     &__lastdays {
//       background: var(--base-inner-container-background-color) !important;
//       text-align: center !important;
//       font-size: 9px;
//     }
//   }

//   &__row {
//     border-bottom: 0px !important;
//     background-color: var(--base-inner-container-background-color) !important;
//     @include textEllipsis();

//     &__name {
//       @include customHeading(24px);
//       &__href {
//         @include customHeading(24px);
//         text-decoration: none;
//       }
//     }

//     &__last_payment {
//       @include customParagraph(14px);
//     }

//     &__created {
//       @include customParagraph(14px);
//       text-decoration: underline;
//     }

//     &__created_by {
//       @include customParagraph(14px);
//       text-decoration: underline;
//     }

//     &__action {
//       &__icons {
//         border-radius: 5px;
//       }
//     }

//     &__button:hover {
//       background: #4ffcb7 !important;
//       color: #000 !important;
//     }

//     &__artist_image {
//       height: 40px !important;
//       width: 40px !important;
//     }
//   }

//   &__label {
//     @include flexProperties(row, flex-start, center, $fullWidth: true);
//     gap: 10px;
//     @include customTags();
//   }

//   &__input {
//     color: var(--base-input-text-color) !important;
//     border: 1px solid #5a7380 !important;
//     background: var(--base-input-text-color) !important;
//     outline: none;
//     padding: 15px;
//     font-size: 16px;
//     border-radius: 12px;
//     width: 100% !important;
//     height: 48px !important;
//   }

//   &__bank_info_wrapper {
//     background-color: var(--base-input-background-color);
//     padding: 15px;
//     border-radius: 12px;
//     margin-top: 8px;
//     height: 300px;
//     overflow-y: auto;
//     border: 1px solid #5a7380 !important;
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none;
//     &::-webkit-scrollbar {
//       display: none !important;
//     }

//     &__name {
//       @include customTags();
//     }
//     &__label {
//       @include customParagraph(15px);
//       font-weight: bold !important;
//     }
//     &__input {
//       @include customParagraph(14px);
//       background-color: #222c41 !important;
//       border: none !important;
//       width: 100% !important;
//       padding: 10px;
//       border-radius: 12px;
//       &__bank_address {
//         background-color: #222c41 !important;

//         padding: 10px;
//         border-radius: 12px;
//         gap: 10px !important;
//       }
//     }
//     &__image {
//       width: 150px;
//       height: 190px;
//       &__downloadbtn {
//         @include activeThemeBotton();
//       }
//     }
//     &__box {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       align-items: center;
//       height: 100%;
//     }
//     p {
//       margin: 0px;
//     }
//   }
// }
.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222c41;
    font-size: 20px;
    font-weight: bold;
    background-color: #4ffcb7;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 60px !important;
  }
}
@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  .table {
    // &__col {

    // }
    overflow-x: scroll;

    &__row {
      &__sosi {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}

@media only screen and (max-width: 600px) {
  .table {
    &__row {
      &__name {
        &__href {
          @include customHeading(20px);
        }
      }
    }
  }
}
