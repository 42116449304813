.page {
  &__tabs_container {
    display: flex;
    gap: 2px;
    border-radius: 15px !important;
    z-index: 10;
    // padding: 10px;

    button {
      //   padding: 15px 20px;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      // width: 100%;
      font-weight: 600;
      border: none;
    }
    button:nth-child(1) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 15px 0px 0px 15px;
      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
    button:nth-child(2) {
      background-color: var(--base-deactive-button-background-color);
      border-radius: 0px 15px 15px 0px;

      color: var(--base-button-text-color);
      transition: all 0.1s ease-in-out;
    }
  }
}
