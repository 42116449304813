@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  margin-top: 20px;
  &__list {
    overflow-y: scroll;
    height: 572px;
    max-height: 572px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.table {
  background: var(--base-container-backgroung-color) !important;
  overflow: scroll;
  height: 450px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &__head {
    position: sticky;
    z-index: 1;
  }

  &__col {
    background: var(--base-container-backgroung-color) !important;
    text-align: left !important;
    width: 50px !important;
    border-bottom: 1px solid var(--base-border-line-color) !important;
    @include textEllipsis();
    &__heading {
      @include customTags();
    }

    &__last {
      background: var(--base-container-backgroung-color) !important;
      text-align: center !important;
    }
    &__lastdays {
      background: var(--base-container-backgroung-color) !important;
      text-align: center !important;
      font-size: 9px;
    }
  }

  &__row {
    border-bottom: 0px !important;
    background-color: var(--base-inner-container-background-color);
    height: 53px !important ;
    @include textEllipsis();

    &__transection_id {
      @include customParagraph(14px);
      text-decoration: underline;
    }

    &__date {
      @include customParagraph(14px);
    }

    &__mode {
      @include customParagraph(14px);
      &__iconContainer {
        height: 30px !important;
        width: 30px !important;
        background-color: #4fb7fc !important;
        &__icon {
          height: 15px !important;
          width: 15px !important;
          color: #000000 !important;
        }
      }
    }
    &__artist_image {
      width: 70px !important;
      height: 70px !important;
    }

    &__href {
      @include customHeading(24px);
      text-decoration: none;
    }

    &__href:hover {
      color: #4ffcb7;
    }

    &__placement {
      @include flexProperties(row, flex-start, center, $fullWidth: false);
    }

    &__green {
      color: green;
    }

    &__red {
      color: red;
    }

    &__name {
      display: flex;
      // width: 300px;
      justify-content: flex-start;
      align-items: center;
    }

    &__sosi {
      // width: 10%;
      font-size: 12px;
    }

    &__action {
      &__icons {
        border-radius: 5px;
      }
    }

    &__button {
      @include activeThemeBotton();
      padding: 15px 10px !important;
    }

    &__button:hover {
      background: #4ffcb7 !important;
      color: #000 !important;
    }
  }

  &__label {
    @include flexProperties(row, flex-start, center, $fullWidth: true);
    gap: 10px;
    color: white;
    margin-bottom: 10px;
    font-size: 1rem;
    font-family: "DM Sans";
  }

  &__input {
    color: #fff !important;
    border: 1px solid #192233 !important;
    background: #192233;
    outline: none;
    padding: 15px;
    font-size: 18px;
    border-radius: 8px;
    width: 100%;
  }

  &__bank_info_wrapper {
    background-color: #192233;
    padding: 15px;
    border-radius: 8px;
    margin-top: 8px;
    height: 200px;
    overflow-y: auto;
    max-height: 200px;

    &__name {
      font-size: 1rem;
      font-family: "DM Sans";
      color: #fff;
    }
    &__label {
      gap: 0;
      font-size: 1rem;
      font-family: "DM Sans";
      color: #fff;
      font-weight: 500;
    }
    &__input {
      background: #192233;
      color: #fff;
      font-size: 0.875rem;
      border: none;
      margin-bottom: 7px;
    }
    &__image {
      width: 30%;
      height: auto;
      &__downloadbtn {
        @include activeThemeBotton();
      }
    }
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
    }
    p {
      margin: 0px;
    }
  }
}
.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222c41;
    font-size: 20px;
    font-weight: bold;
    background-color: #4ffcb7;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 60px !important;
  }
}
@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  .table {
    // &__col {

    // }
    overflow-x: scroll;

    &__row {
      &__sosi {
        width: 100%;
      }
    }
  }
  .modalCss {
    width: 90% !important;
  }
}
