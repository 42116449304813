@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 694px;
  padding: 20px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__heading {
      color: var(--base-heading-color);
      font-size: 20px !important;
      font-weight: bold !important;
    }
    &__select {
      &__icon {
        color: var(--base-action-icon-color);
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
        cursor: pointer;
      }
      &__menuItem {
        font-size: 12px !important;
        font-weight: 700 !important;
      }
    }
  }
  &__table {
    height: 550px !important;
    margin-top: 15px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__topHeadHeadings {
    @include customTags();
  }

  &__name {
    @include textEllipsis(120px);
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading1 {
    @include textEllipsis(100px);
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading2 {
    @include customHeading(16px);
    font-weight: 700 !important;
  }

  &__actionIcon {
    background-color: var(--base-action-icon-background) !important;
    width: 30px;
    height: 30px;
    svg {
      fill: var(--base-action-icon-color);
      width: auto;
      height: 16px;
    }
  }

  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }

  &__button {
    @include activeThemeBotton();
    margin-top: 20px !important;
  }

  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }
}
