@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 675px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--base-border-line-color);
    padding: 20px;

    &__heading {
      color: var(--base-heading-color);
      font-size: 20px !important;
      font-weight: bold !important;
    }
    &__button {
      @include activeThemeBotton();
      width: 78px !important;
      height: 32px !important;
    }
  }
  &__container {
    padding: 20px;
    &__downloadSign {
      @include activeThemeBotton();
      width: auto !important;
      padding: 0px 20px !important;
    }
    &__heading {
      @include customTags();
      // padding-bottom: 10px;
    }
    &__signature_wrapper {
      position: relative;
      width: 100%;
      height: 200px;
      &__sign {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff !important;
        border: 1px solid #5a7380;
        border-radius: 12px;
      }
      &__icon {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__icon_cross {
          background-color: var(--base-action-icon-background) !important;
          width: 22px;
          height: 22px;
          padding: 1px !important;
          svg {
            fill: var(--base-action-icon-color);
            font-size: 30px;
          }
        }
      }
    }
    &__upload_btn {
      background-color: transparent !important;
      // background-color: var(--base-button-background-color) !important;
      color: var(--base-button-background-color) !important;
      border-radius: 12px !important;
      width: 100% !important;
      border: 2px dashed var(--base-button-background-color) !important;
      font-size: 14px !important;
      height: 40px;
      svg {
        width: 20px;
        height: 20px;
        margin-top: 10px;
        fill: var(--base-icon-color) !important;
      }
    }
    &__sign_btn {
      margin-top: 30px !important;
      @include activeThemeBotton();
    }
  }
}

@media only screen and (max-width: 1535px) {
  .page {
    height: 100%;
  }
}
