@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, center, center, $fullWidth: true);
  background-color: var(--base-main-bg-color);
  background-image: var(--base-main-bg-shades);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100% !important;
  background-attachment: fixed;
  overflow: hidden;
  &__centerContainer {
    @include flexProperties(column, center, center, $fullWidth: false);
    // &__mainHeading {
    //   @include customHeading(80px);
    //   color: #222c41 !important;
    // }
    &__404Img {
      img {
        width: 250px !important;
      }
    }
    &__headingSecondary {
      @include customHeading(25px);
      color: #070707 !important;
    }
    &__BtnContainer {
      @include flexProperties(row, center, center, $fullWidth: true);
      width: 100% !important;
      gap: 20px;
      margin-top: 10px;
      &__button {
        // @include activeThemeBotton();
        padding: 10px 35px !important;
        background-color: #070707 !important;
        color: #fff !important;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
}

@media only screen and (min-width: 1201px) {
}

@media only screen and (max-width: 440px) {
}

@media only screen and (max-width: 340px) {
}
