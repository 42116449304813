@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  &__table {
    background: #222c41;
    padding: 20px;
  }

  &__box {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    gap: 40px;
    background: #222c41;
    padding: 20px;

    &__header {
      @include flexProperties(row, flex-start, center, $fullWidth: true);
      gap: 20px;

      &__icon {
        cursor: pointer;
      }

      &__title {
        color: #4ffcb7;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold;
      }
    }

    &__details {
      @include flexProperties(row, flex-start, center, $fullWidth: true);
      gap: 20px;

      &__avatar {
        height: 120px !important;
        width: 120px !important;
        border: 2px solid white;
      }

      &__contain {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        gap: 20px;

        &__box {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          gap: 20px;

          &__key {
            color: #4ffcb7;

            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: bold;
          }

          &__value {
            color: white;

            text-transform: uppercase;
            letter-spacing: 3px;
          }
        }
      }
    }

    &__tracks {
      width: 100%;

      &__list {
        border-top: 1px solid #8e8e8e;
        overflow: auto;
        height: 415px;
        max-height: 415px;

        &__ul {
          height: 415px;
          overflow: auto;
          list-style: none;
          padding: 0;
          margin: 0;

          &__li {
            @include flexProperties(
              row,
              space-between,
              center,
              $fullWidth: true
            );
            gap: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding: 20px;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            &__name {
              font-size: 12px;
              text-transform: uppercase;
              letter-spacing: 2px;
              color: #4ffcb7;
              font-weight: bold;
            }
          }
        }

        &__loader {
          @include flexProperties(column, center, center, $fullWidth: true);
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .page {
    &__box {
      &__details {
        @include flexProperties(column, center, center, $fullWidth: true);
        gap: 20px;
        width: 100%;
      }

      &__tracks {
        &__list {
          height: 970px;
          max-height: 970px;

          &__ul {
            height: 970px;

            &__li {
              @include flexProperties(
                column,
                space-between,
                center,
                $fullWidth: true
              );

              &__name {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 364px) {
  .page {
    &__box {
      &__header {
        &__title {
        }
      }
    }
  }
}

@media only screen and (max-width: 336px) {
  .page {
    &__box {
      &__header {
        &__title {
        }
      }
    }
  }
}

@media only screen and (max-width: 330px) {
  .page {
    &__box {
      &__details {
        &__contain {
          &__box {
            &__key {
            }

            &__value {
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  .page {
    &__box {
      &__header {
        &__title {
        }
      }
    }
  }
}

@media only screen and (max-width: 307px) {
  .page {
    &__box {
      &__header {
        &__title {
        }
      }

      &__details {
        &__contain {
          &__box {
            &__key {
            }

            &__value {
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 287px) {
  .page {
    &__box {
      &__details {
        &__contain {
          &__box {
            &__key {
            }

            &__value {
            }
          }
        }
      }
    }
  }
}
