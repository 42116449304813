@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.dialog {
  &__title {
    background-color: #4ffcb7;
    text-align: center !important;
    color: #222c41 !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  &__content {
    &__text {
      color: #ffffff !important;
      font-size: 14px;
    }
  }

  &__actions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    &__noButton {
      padding: 5px 60px !important;
      border: 1px solid #e12020 !important;
      border-radius: 12px !important;
      color: #e12020 !important;
      font-size: 14px !important;
      font-weight: 700 !important;
    }

    &__yesButton {
      padding: 5px 40px !important;
      background-color: #4ffcb7 !important;
      border-radius: 12px !important;
      color: #222c41 !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      &:hover {
        background-color: #4ffcb7;
        color: #222c41 !important;
        font-weight: 700 !important;
      }
    }
  }
}
