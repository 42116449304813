@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.dialog {
  &__title {
    background-color: var(--base-title-bg-color);
    text-align: center !important;
    color: var(--base-title-font-color) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  &__content {
    &__text {
      color: #ffffff !important;
      font-size: 14px;
    }
  }

  &__actions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    &__noButton {
      padding: 5px 60px !important;
      border: 1px solid #e12020 !important;
      border-radius: 12px !important;
      color: #e12020 !important;
      font-size: 14px !important;
      font-weight: 700 !important;
    }

    &__yesButton {
      padding: 5px 40px !important;
      background-color: var(--base-button-background-color) !important;
      border: 1px solid var(--base-button-background-color) !important;
      border-radius: 12px !important;
      color: var(--base-button-text-color) !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      &:hover {
        background-color: var(--base-button-background-color) !important;
        color: var(--base-button-text-color) !important;
      }
    }
  }
}
