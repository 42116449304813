@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.wrapper {
  position: relative;
  background: #192233;
  // border: 1px solid #000;
  // box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.71);
  width: 100%;
  margin: 0 auto;
}
.chart_line {
  position: relative;
  margin-top: -38px;
}
