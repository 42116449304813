@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  &__artist {
    flex: 1;
    width: 100%;

    &__box {
      min-height: 700px;
      @include flexProperties(column, flex-start, center, $fullWidth: false);
      // background: $component-box-gradient-color;
      // padding: 20px;
      border-radius: 8px;
      gap: 0px;
      width: 100%;

      &__image {
        @include flexProperties(row, center, center, $fullWidth: false);

        &__outer {
          @include flexProperties(row, center, center, $fullWidth: false);
          height: 190px;
          width: 190px;
          border-radius: 50%;
          background: linear-gradient(90deg, #36a1ff, #0a1230);

          &__inner {
            @include flexProperties(row, center, center, $fullWidth: false);
            height: 180px;
            width: 180px;
            border-radius: 50%;
            background: linear-gradient(128deg, #016da7 56%, #870033f2 120%);

            &__avatar {
              height: 140px !important;
              width: 140px !important;
            }
          }
        }
      }

      &__name {
        font-size: 32px;
        text-transform: capitalize;
        font-weight: bold;
        color: #4ffcb7;
        letter-spacing: 3px;
        text-align: center;
      }

      &__btn_container {
        &__btn {
          @include activeThemeBotton();
        }

        &__btn_pink {
          @include activeThemeBotton();

          text-transform: capitalize !important;
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        width: 100%;
        background: #222c41;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: #4ffcb7;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: capitalize;
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 10px !important;
            font-size: 16px !important;
            cursor: pointer;
            text-transform: capitalize !important;
            width: 25%;
            height: 45px;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
              border-color: #4ffcb7 !important;
            }
          }
        }

        &__content {
          @include flexProperties(
            row,
            space-between,
            center,
            $fullWidth: false
          );
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 40px;

          &__box {
            @include flexProperties(row, flex-start, center, $fullWidth: false);
            flex-wrap: wrap;
            gap: 10px;
            width: 45%;

            &__title {
              color: #4ffcb7;
              font-size: 16px;

              &__image {
                height: 20px;
                width: 20px;
                cursor: pointer;
              }
            }

            &__value {
              color: white;
              font-size: 14px;
            }
          }
        }
      }

      &__tracks {
        width: 100%;
        background: #222c41;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: #4ffcb7;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 25px !important;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
            }
          }
          &__btn_group {
            background-color: #1e242e;
            padding: 5px;
            border-radius: 10px !important;
            &__btn {
              @include activeThemeBotton();

              line-height: 16px !important;
              font-weight: 200 !important;
              width: auto;
              background-color: transparent !important;
              border: 0px !important;
              white-space: nowrap;

              &:hover {
                background-color: #4ffcb7 !important;
              }
            }
          }
        }
      }
    }
  }

  &__similar {
    flex: 1;
    height: 100%;
    width: 100%;

    &__box {
      background: #222c41;
      border-radius: 12px;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}
