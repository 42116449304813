@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.tabDisplay {
  display: block;
}

.tabHide {
  display: none;
}

.page {
  @include flexProperties(column, center, flex-start, $fullWidth: true);
  // gap: 20px;
  font-family: "DM Sans" !important;

  &__artist {
    // flex: 1;

    width: 100%;

    &__box {
      background: var(--base-container-backgroung-color);
      border-radius: 12px;
      width: 100%;
      height: 675px !important;
      max-height: 675px !important;

      &__topdetails {
        @include flexProperties(row, center, center, $fullWidth: true);
        width: 100% !important;
        height: 20.4% !important;
        // flex-wrap: nowrap;
        padding: 0px 20px 0px 20px;
        gap: 20px;

        &__image {
          height: 89px !important;
          width: 89px !important;
          object-fit: cover;
          border-radius: 50%;
          position: relative;

          .onlineInd {
            position: absolute;
            bottom: 15px;
            right: 0;
            width: 16px;
            height: 16px;
            background-color: #4ffcb7;
            border-radius: 50%;
          }
        }

        &__details {
          align-items: center;
          justify-content: center;
          width: 100%;
          // padding-right: 20px;

          &__artistname {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            &__name {
              @include customHeading(1.2rem);
              @include textEllipsis(180px);
              text-align: left;
            }
            &__icon_container {
              margin-top: 0.5 !important;
              cursor: pointer !important;
              background-color: #4ffcb7 !important;
              height: 30px !important;
              width: 30px !important;
              &:hover {
                background-color: #4ffcb7;
              }
            }
          }
          &__email {
            @include customParagraph(14px);
            text-decoration: underline;
          }
          &__country {
            align-items: center;
            // &__flag {
            //   // @include customParagraph(14px);
            //   // margin-left: 5px !important;
            // }
            &__name {
              @include customParagraph(14px);
              margin-left: 5px;
            }
          }
        }

        &__editicon {
          display: flex;
          flex: 1;
          align-items: flex-start;
        }
      }

      &__horizontalline {
        width: calc(100%) !important;
        height: 1px !important;
        background-color: var(--base-border-line-color);
        position: relative;
      }

      &__listnership {
        @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        min-height: 15.41% !important;
        max-height: 15.41% !important;
        padding: 20px !important;

        &__topheading {
          @include customTags();
        }
        &__innerlyer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          &__text2 {
            @include customHeading(38px);
          }
          &__trending {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            justify-content: center;
          }
          &__text3 {
            font-size: 12px !important;
          }
        }
      }

      &__platforms {
        @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        min-height: 15.26% !important;
        max-height: 15.26% !important ;
        padding: 20px;

        &__text {
          @include customTags();
        }

        &__socials {
          width: 100%;
          display: flex;
          gap: 7px;
          margin-top: 8px !important;
          &__icons {
            width: 26px;
            height: 26px;
            // margin: auto;
            display: block;
          }
        }
      }

      &__genre {
        // @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        // overflow-x: scroll;
        // overflow-y: hidden;
        min-height: 13.78% !important;
        max-height: 13.78% !important;
        background: var(--base-container-backgroung-color);
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }

      &__tracks_info {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        width: 100%;
        min-height: 15.56% !important;
        max-height: 15.56% !important;
        padding: 20px;
        gap: 10px !important;
        &__left {
          width: 30% !important;
          &__text1 {
            @include customTags();
          }
          &__text2 {
            @include customHeading(28px);
          }
        }
        &__right {
          // @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
          // width: 70% !important;
          &__top_details {
            gap: 5px !important;
            overflow: hidden;
            @include customParagraph(12px);
          }
          &__update_botton {
            display: flex;
            width: 100%;
            background: "transparent" !important;
            // background: var(--base-button-background-color) !important;
            border: 2px solid var(--base-button-background-color) !important;
            color: var(--base-button-background-color) !important;
            border-radius: 12px !important;
            font-size: 12px !important;
            font-weight: 700 !important;
            cursor: pointer;
            text-transform: capitalize !important;
            border: none;
            white-space: nowrap;
            height: 40px !important;
          }
        }
      }

      &__buttons_container {
        @include flexProperties(column, center, center, $fullWidth: true);
        width: 100% !important;
        min-height: 17.78% !important;
        max-height: 17.78% !important;
        padding: 0px 20px 0px 20px;
        &__view_funding_dashbord {
          @include activeThemeBotton();
          height: 40px !important;
          margin-bottom: 10px !important;
          &__inner_icon {
            color: var(--base-button-text-color);
          }
        }
        &__edit_dashbord_btn {
          height: 40px !important;

          @include deActiveThemeBotton();
          // &__inner_icon {
          // }
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 1;
    width: 100% !important;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100% !important;
      height: 675px !important;
      max-height: 675px !important;
      background-color: var(--base-container-backgroung-color) !important;
      border-radius: 12px !important;
      padding: 20px !important;

      &__header {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: false
        );
        &__title {
          @include customHeading(24px);
        }
      }
    }
  }

  &__similar {
    flex: 1;
    height: 100%;
    width: 100%;

    &__box {
      background: var(--base-container-backgroung-color);
      border-radius: 12px !important;
      overflow: hidden;
    }
  }
  &__MainBox {
    background-color: var(--base-container-backgroung-color) !important;
    border-radius: 12px;
    border: 2px solid #4ffcb7;
    height: 460px;
    // transition: display 0.3s ease, height 0.3s ease;

    &__edit {
      padding: 15px 20px;
      border-bottom: 2px solid #4ffcb7;
      &__mode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__text {
          color: #4ffcb7 !important;
          font-size: 14px !important;
        }
        &__icons {
          background-color: #4ffcb7 !important;
          width: 38px !important;
          height: 38px !important;
          font-weight: bold !important;
        }
      }
    }
  }

  &__checkAndBtn {
    // display: flex;
    // margin: 10px !important;
    gap: 10px;
    background-color: var(--base-container-backgroung-color);
    padding: 10px 20px;
    border-radius: 12px !important;
    &__text {
      color: #4ffcb7;
      white-space: nowrap;
      font-size: 12px !important;
    }
    &__checkBox {
      svg {
        color: #4ffcb7;
      }
    }
    &__hideAndShow {
      @include activeThemeBotton();
      padding: 1px 20px !important;
      font-size: 12px !important;
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
    &__artist {
      &__box {
        &__tracks_info {
          &__container {
            &__text2 {
              font-size: 28px !important;
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
    &__artist {
      &__box {
        &__tracks_info {
          &__container {
            &__text2 {
              font-size: 28px !important;
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }
  .page__artist__box__topdetails__details__artistname__name {
    width: 100% !important;
  }
  .page__artist__box__topdetails__details__email {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1201px) {
  .page {
    &__artist {
      &__box {
        &__tracks_info {
          &__container {
            &__text2 {
              font-size: 28px !important;
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }
  .tabDisplay {
    display: none;
  }

  .tabHide {
    display: block;
  }
}

@media only screen and (max-width: 1356px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          gap: 10px;
          padding-left: 10px;
          height: 100px !important;

          &__image {
            height: 80px !important;
            width: 80px !important;
          }
          &__details {
            &__artistname {
              font-size: 22px;
            }
            &__email {
              font-size: 12px;
            }
            &__country {
              font-size: 12px;
              // &__flag {
              // }
            }
          }
          &__editicon {
            height: 20px !important;
            width: 20px !important;
          }
        }
        &__tracks_info {
          &__container {
            &__text2 {
              font-size: 28px !important;
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1637px) {
  .page {
    &__artist {
      &__box {
        &__tracks_info {
          &__container {
            &__text {
              color: #4ffcb7;
              font-size: 8px !important;
              font-weight: 500 !important;
            }
            &__text2 {
              color: #fff;
              font-size: 22px !important;
              font-weight: 700 !important;
              // text-align: center;
            }
          }
          &__containerright {
            flex-wrap: nowrap;
            // &__heading_contianer {
            // }
            &__text {
              font-size: 8px !important;
              // padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
  .page__artist__box__topdetails__details__email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 150px;
  }
}

@media only screen and (max-width: 1821px) {
  .page {
    &__artist {
      &__box {
        &__tracks_info {
          &__container {
            &__text {
              color: #4ffcb7;
              font-size: 12px !important;
              font-weight: 500 !important;
            }
            &__text2 {
              color: #fff;
              font-size: 28px !important;
              font-weight: 700 !important;
              // text-align: center;
            }
          }
          &__containerright {
            flex-wrap: nowrap;
            // &__heading_contianer {
            // }
            &__text {
              font-size: 10px !important;
              // padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  display: block;
  position: relative;
  &:hover {
    .controls {
      display: block !important;
    }
  }

  .controls {
    width: 85px;
    height: 85px;
    border: 2px solid #4ffcb7;
    position: absolute;
    display: none !important;
    background-color: rgba(0, 0, 0, 0.582);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    z-index: 2;
    top: 0;
    text-align: center;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .add {
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #fff;
      cursor: pointer;
    }
  }
}

// @media only screen and (max-width: 1400px) {
//   .page__artist__box__topdetails__image {
//     width: 70px !important;
//     height: 70px !important;
//   }
//   .skeleton {
//     width: 70px !important;
//     height: 70px !important;
//   }
//   .onlineInd {
//     bottom: 10px !important;
//   }
//   .page__artist__box__topdetails__details__artistname__name {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: block;
//     width: 100px;
//   }
// }

@media only screen and (max-width: 1600px) {
  .page__artist__box__topdetails__image {
    width: 70px !important;
    height: 70px !important;
  }
  .skeleton {
    width: 70px !important;
    height: 70px !important;
  }
  .onlineInd {
    bottom: 10px !important;
  }
  .page__artist__box__topdetails__details__artistname__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 110px;
  }
  .page__artist__box__topdetails__details__email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 110px;
  }
}
