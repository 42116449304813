@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  &__tabs_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-top: -75px;
    padding: 20px;
  }

  &__stepper_btn_wrapper {
    &__steps {
      // background-color: #222c41;
      // border: 1px solid #4ffcb7;
      border-radius: 10px;
      padding: 8px 8px !important;
      width: 20%;
      text-align: center;
      color: #fff !important;
    }
  }
  &__preview_btn {
    @include activeThemeBotton(); // width: 100%;
    padding: 8px 20px !important ;

    &:hover {
      background: var(--base-button-background-color) !important;
      color: var(--base-button-text-color) !important;
    }
  }

  &__download_btn {
    background: #5a7380 !important;
    color: #fff !important;
    border-radius: 12px !important;
    font-size: 16px;
    font-weight: 700 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    width: 100%;
    border: none;
    padding: 10px 10px !important;
    // margin-right: 20px !important;

    &:hover {
      background: #5a7380 !important;
      color: #fff !important;
    }
  }
  &__return_home_btn {
    @include activeThemeBotton();
  }
  &__btn_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    &__back_btn {
      border-radius: 12px !important;
      background: transparent !important;
      border: 2px solid var(--base-button-background-color) !important;
      color: var(--base-button-background-color) !important;
      padding: 6px 20px !important;
      font-weight: bold !important;
      width: 100% !important;
    }
    &__save_btn {
      border-radius: 12px !important;
      background: var(--base-button-background-color) !important;
      border: 2px solid var(--base-button-background-color) !important;
      color: var(--base-button-text-color) !important;
      padding: 6px 20px !important;
      font-weight: bold !important;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .page {
    &__tabs_container {
      margin-top: -10px;
    }
  }
}
