@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  &__bottomSec {
    margin-top: 10px;
    &__trending {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__heading {
        color: var(--base-heading-color) !important;
        font-size: 20px !important;
        font-weight: bold !important;
      }
      &__select {
        &__icon {
          color: var(--base-select-svg-color);
          font-size: 30px;
          font-weight: bold;
          margin-right: 10px;
          cursor: pointer;
        }
        &__menuItem {
          font-size: 12px !important;
          font-weight: 700 !important;
        }
      }
    }
    &__list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 550px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &__box {
        background-color: var(--base-inner-container-background-color);
        border-radius: 12px;
        height: 70px !important;
        &__content {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          padding: 10px;
          &__details {
            display: flex;
            align-items: center;
            gap: 15px;
            &__detail {
              &__name {
                font-size: 16px !important;
                color: var(--base-paragraph-color) !important;
                font-weight: bold !important;
              }
              &__listners {
                color: var(--base-tags-color) !important;
                font-size: 12px !important;
                font-weight: 500 !important;
              }
            }
          }
          &__flag {
            img {
              border-radius: 50%;
              width: 26px !important;
              height: 26px !important;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}

@media only screen and (max-width: 1750px) {
  .page {
    &__topSec {
      &__tabs_container {
        button {
          white-space: nowrap;
          font-size: 11px !important;
        }
      }
    }
  }
}
