@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  width: 100%;
  background: rgba(84, 84, 84, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  font-family: "DM Sans";
  padding: 20px 30px;
  overflow: hidden;
  // height: 619px !important;
  &__logo {
    @include flexProperties(row, center, center, $fullWidth: false);
    height: 100px !important;
    max-height: 100px !important;
    background-image: var(--side-drawer-top-icon);
    background-position: center;
    background-size: 107px;
    background-repeat: no-repeat;

    &__img {
      height: 100px;
      margin-bottom: 20px;
    }
  }

  &__horizontalLine {
    width: 100%;
    background-color: #707070;
    height: 2px;
    opacity: 0.5;
    margin: 20px 0px;
  }
  &__tabs_container {
    &__tab {
      width: 50% !important;
      font-size: 12px !important;
      font-weight: bold !important;
      min-height: 38px !important;
      padding: 0px !important;
      text-transform: uppercase !important;
      color: #fff !important;
      &__activeTabEmp {
        background-color: var(--base-tab-active-background) !important;
        border-radius: 12px !important;
        color: #222c41 !important;
        position: relative;
        animation: slideInEmp 0.3s ease-in-out;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: var(--base-tab-active-background);
          border-radius: 12px;
          transform: scaleX(0);
          transform-origin: 0% 50%;
          animation: slideInUnderlineEmp 0.3s ease-in-out;
        }
        @keyframes slideInEmp {
          to {
            transform: translateX(0%);
          }
          from {
            transform: translateX(100%);
          }
        }

        @keyframes slideInUnderlineEmp {
          to {
            transform: scaleX(0);
          }
          from {
            transform: scaleX(1);
          }
        }
      }
      &__activeTabArt {
        background-color: var(--base-tab-active-background) !important;
        border-radius: 12px !important;
        color: #222c41 !important;
        position: relative;
        animation: slideInArt 0.3s ease-in-out;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: var(--base-tab-active-background);
          border-radius: 12px;
          transform: scaleX(0);
          transform-origin: 0 50%;
          animation: slideInUnderlineArt 0.3s ease-in-out;
        }
        @keyframes slideInArt {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }

        @keyframes slideInUnderlineArt {
          from {
            transform: scaleX(0);
          }
          to {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  &__form {
    &__main {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 100%;
      gap: 10px;

      &__field_container {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        width: 100%;
        gap: 10px;

        &__label {
          @include customTags();
        }

        &__input {
          color: #000 !important;
          border-style: none;
          background: #ffffff !important;
          outline: none;
          padding: 12px 15px;
          font-size: 14px;
          border-radius: 12px;
        }
      }

      &__form_action {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        width: 100%;
        &__forgotPasswordText {
          color: red;
          text-decoration: underline;
        }
      }

      &__action {
        @include flexProperties(row, center, center, $fullWidth: true);
        width: calc(100%);
        margin-top: 20px;

        &__btn {
          @include activeThemeBotton();
          width: 100%;
        }

        &__register_btn {
          @include activeThemeBotton();
        }
      }

      &__dnthaveaccount {
        @include customParagraph(12px);
        font-weight: 400 !important;
        &__spantwo {
          text-decoration: none;
          padding-left: 10px;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__social_media {
    margin-top: 20px;

    &__button {
      padding: 5px;
      height: 100%;
      background: "#FFFFFF";
      width: 100%;
      cursor: pointer;

      &__img {
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 466px) {
  .page {
    padding: 20px;
  }
}

@media only screen and (max-width: 386px) {
  .page {
    padding: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .page {
    padding: 20px;
    &__logo {
      &__text {
        font-size: 22px !important;
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  .page {
    padding: 20px;
    &__logo {
      &__text {
        font-size: 18px !important;
      }
    }
  }
}

@media only screen and (max-width: 326px) {
  .page {
    padding: 20px;
    &__logo {
      gap: 10px;
      margin-bottom: 10px;
      &__img {
        height: 80px;
      }
      &__text {
        font-size: 16px !important;
      }
    }
  }
}
