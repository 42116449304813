@import "../../styles/scss.scss";
@import "../../styles/colors.scss";
.container {
  width: 100%;
  position: relative;
  z-index: 999;

  &__search_box {
    width: 100% !important;
    height: 41px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__search_input {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #020923, $alpha: 0.4);
      outline: none;
      padding: 15px;
      border-radius: 12px !important;
      border: none;
      @include customParagraph(14px);
      &::placeholder {
        @include customParagraph(14px);
        opacity: 0.8;
      }
    }
  }

  &__list {
    position: absolute;
    z-index: 2;
    top: 50px;
    list-style: none;
    width: calc(100%);
    padding: 0;
    margin: 0;
    background: var(--base-container-backgroung-color);
    border: 1px solid var(--base-img-border-color);
    padding: 20px;
    border-radius: 12px !important;
    max-height: 300px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &__found {
      padding: 10px 0px 0px 10px;
      font-size: 16px !important;
      font-weight: bold !important;
      color: var(--base-tags-color);
    }
    &__list_item {
      border-bottom: 1px solid var(--base-border-line-color);
      cursor: pointer;

      &__contains {
        display: flex;
        margin: 5px 0px;
        align-items: center;
        gap: 20px;
        padding: 10px;
        &:hover {
          background-color: var(--base-hover-list-color);
          border-radius: 12px;
        }
        &__avatar {
          border: 2px solid var(--picture-border-color);
          width: 45px !important;
          height: 45px !important;
          border-radius: 50%;
        }
        &__title {
          @include customHeading(18px);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    &__search_box {
      &__search {
        &_input {
          font-size: 20px;
          overflow: hidden;
        }
      }
    }
  }
}
