@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  @include flexProperties(row, center, center, $fullWidth: true);
  min-height: 45vh;
  height: 100%;
  width: 100%;
  &__chart {
    width: 100% !important;
  }
}
