@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  width: 100% !important;
  background: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  padding: 20px 20px 20px 20px;
  &__fieldsContainer {
    @include flexProperties(column, center, center, $fullWidth: true);
    &__image_container {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 40px;

      &__avatar_container {
        position: relative;
        &__avatar {
          height: 89px !important;
          width: 89px !important;
          border: 4px solid var(--base-img-border-color);
        }
        &__icon {
          position: absolute;
          top: 30px;
          left: 30px;
        }
      }
    }
    &__title {
      @include customHeading();
    }
    &__form {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100% !important;
      margin-top: 20px;
      gap: 20px;
      padding-bottom: 40px;
      &__avatar {
        height: 89px !important;
        width: 89px !important;
        border: 4px solid var(--base-img-border-color);
      }

      &__formfield {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          @include customTags();
          margin-left: 10px;
          &__image {
            height: 16px;
            width: 16px;
          }
        }

        &__input {
          color: var(--base-input-text-color) !important;
          background: var(--base-input-background-color);
          outline: none;
          padding: 15px;
          font-size: 16px;
          border-radius: 12px;
          width: 100%;
          height: 48px;
        }
        &__select_icon {
          color: var(--base-icon-color) !important;
          font-size: 20px;
          font-weight: bold;
          margin-right: 15px;
          cursor: pointer;
        }

        &__phoneinput {
          border-radius: 12px;
          padding: 13px 15px;
          color: var(--base-input-text-color) !important;
          background: var(--base-input-background-color);

          input {
            color: var(--base-input-text-color) !important;
            background: var(--base-input-background-color);
            outline: none;
            font-size: 16px;
            border: none;
          }
        }
      }

      &__cancel_btn {
        @include cancelDeactiveBtn();
        padding: 10px !important;
        height: 48px !important;
      }

      &__submit_btn {
        @include activeThemeBotton();
        padding: 10px !important;
        height: 48px !important;
        border: 2px solid var(--base-button-background-color) !important;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 600px) {
}
