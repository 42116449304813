@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__heading {
    font-weight: 700 !important;
    color: #f6f6f6 !important;
  }

  &__box {
    padding: 20px;
    background: #222c41;
    border-radius: 12px;
    width: 100%;
    height: 635px;
    border: 1px solid var(--base-border-line-color);

    &__image {
      position: relative !important;
      display: flex;
      flex: 1;
      align-items: center;
      gap: 40px;

      &__btn {
        position: absolute !important;
        top: 30px !important;
        left: 30px !important;
      }
      &__title {
        color: #f6f6f6 !important;
        font-weight: 700 !important;
      }
    }

    &__form {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      gap: 30px;
      margin-top: 30px;
      margin-bottom: 30px;

      &__field_container {
        @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
        gap: 24px;
        width: 100%;

        &__field {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          gap: 10px;

          &__label {
            margin-left: 10px;
            @include customTags();
          }

          &__input {
            // color: #979797 !important;
            // border: 2px solid #192233 !important;
            // background: #192233;
            // outline: none;
            // padding: 15px;
            // font-size: 18px;
            // border-radius: 8px;
            width: 100%;

            // &:focus {
            //   border-color: #4ffcb7 !important;
            //   transition: all 0.3s ease-in-out !important;
            // }
          }

          &__select_icon {
            color: var(--base-icon-color) !important;
            font-size: 20px;
            font-weight: bold;
            margin-right: 15px;
            cursor: pointer;
          }

          &__phoneinput {
            color: #979797 !important;
            background: #192233;
            padding: 15px;
            font-size: 18px;
            border-radius: 12px;
            width: 100%;
            height: 48px !important;

            input {
              color: #c2c2c2 !important;
              background: transparent;
              outline: none;
              font-size: 16px;
              border: none;
            }

            option {
              background-color: #222c41;
              color: #c2c2c2;
            }
          }
        }
      }

      &__actions {
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        width: 100%;

        &__cancel_btn {
          @include cancelDeactiveBtn();
          height: 48px !important;
        }

        &__submit_btn {
          @include activeThemeBotton();
          height: 48px !important;
        }
      }
    }
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__box {
      height: 100%;
      &__image {
        @include flexProperties(row, center, center, $fullWidth: true);

        &__btn {
          left: 45% !important;
        }
      }

      &__form {
        &__field_container {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          width: 100%;

          &__field {
            width: 100%;
          }
        }
      }
    }
  }
}
