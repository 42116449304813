@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  // input[type="number"] {
  //   -moz-appearance: textfield;
  // }

  &__fieldsContainer {
    background: #2f3443;
    border-radius: 8px;

    &__image_container {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 40px;

      &__avatar_container {
        position: relative;

        &__icon {
          position: absolute;
          top: 55px;
          left: 55px;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 20px;
      gap: 40px;
      padding-bottom: 40px;
      // width: 90%;

      &__formfield {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          gap: 10px;
          margin-left: 10px;
          @include customTags();
          &__image {
            height: 18px;
            width: 18px;
          }
        }

        &__input {
          color: #979797 !important;
          border: 2px solid #192233 !important;
          background: #192233;
          outline: none;
          padding: 0px 20px 0px 20px;
          font-size: 14px;
          border-radius: 8px;
          width: 100%;
          height: 40px !important;
          &:focus {
            border-color: #4ffcb7 !important;
            transition: all 0.3s ease-in-out !important;
          }
          option {
            background-color: #192233;
          }
        }

        &__select {
          border: 1px solid #4ffcb7 !important;
          background: #192233;
          padding: 0px;
          border-radius: 12px !important;
          color: #fff !important;
          outline-color: transparent;
          font-size: 14px !important;

          option {
            padding: 0;
            font-size: 14px !important;
          }
        }

        &__button {
          @include activeThemeBotton();
          background-color: #4ffcb7 !important;
          text-transform: capitalize;
          color: #222c41 !important;
          font-weight: bold !important;
          // padding: 12px;
          height: 48px !important;

          &:hover {
            background-color: #4ffcb7 !important;
          }

          &__upload {
            margin-top: 5px;
            margin-right: 5px;
          }
        }

        &__phoneinput {
          border: 1px solid #4ffcb7 !important;
          border-radius: 8px;
          padding: 15px;
          color: #979797 !important;

          input {
            color: #979797 !important;
            background: transparent;
            outline: none;
            font-size: 16px;
            border: none;
          }

          option {
            background-color: #222c41;
            color: #c2c2c2;
          }
        }
      }

      &__action {
        gap: 60px;
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        width: 100%;

        &__cancel_btn {
          @include activeThemeBotton();
          height: 40px;
          padding: 5px 10px !important;
        }

        &__submit_btn {
          @include activeThemeBotton();
          height: 40px;
          padding: 5px 10px !important;
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      border-radius: 12px;
      margin-top: 20px;

      &__adetails {
        width: 100%;
        background: #222c41;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            @include customHeading(20px);
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 10px !important;
            font-size: 16px !important;
            cursor: pointer;
            text-transform: capitalize !important;
            width: 25%;
            height: 45px;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
              border-color: #4ffcb7 !important;
            }
          }
        }

        &__content {
          @include flexProperties(
            row,
            space-between,
            center,
            $fullWidth: false
          );
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 40px;

          &__box {
            @include flexProperties(row, flex-start, center, $fullWidth: false);
            flex-wrap: wrap;
            gap: 10px;
            width: 45%;

            &__title {
              color: #4ffcb7;
              font-size: 16px;

              &__image {
                height: 20px;
                width: 20px;
                cursor: pointer;
              }
            }

            &__value {
              color: white;
              font-size: 14px;
            }
          }
        }
      }

      &__tracks {
        width: 100%;
        background-color: #222c41 !important;
        border: 2px solid #4ffcb7 !important;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: #4ffcb7;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 25px !important;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}
