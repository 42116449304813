@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, center, center, $fullWidth: true);
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "DM Sans";

  &__dialog {
    background-color: var(--base-container-backgroung-color);
    border-radius: 12px;
    padding: 20px;

    &__header {
      &__title {
        @include customHeading();
      }
    }

    &__form {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      gap: 30px;
      margin-top: 50px;
      margin-left: 30px;
      margin-right: 30px;

      &__field_container {
        @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
        gap: 24px;
        width: 100%;

        &__field {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          gap: 10px;

          &__label {
            @include customTags();
          }

          &__input {
            color: #ffffff !important;
            border: 1px solid #5a7380 !important;
            background: #192233;
            outline: none;
            padding: 0px 20px 0px 20px;
            font-size: 14px;
            border-radius: 8px;
            width: 100%;
            height: 40px !important;
            &:focus {
              border-color: #4ffcb7 !important;
              transition: all 0.3s ease-in-out !important;
            }
            option {
              background-color: #192233;
            }
          }
          // &__select {
          //   color: var(--base-input-text-color) !important;
          //   background: var(--base-input-text-color) !important;
          //   outline: none;
          //   padding: 15px;
          //   font-size: 18px;
          //   border-radius: 12px !important;
          //   height: 48px;
          //   width: 100%;
          //   svg {
          //     fill: #4ffcb7;
          //   }
          //   &__options {
          //     background-color: var(--base-inner-container-background-color) !important;
          //     color: #979797 !important;
          //     font-size: 18px;
          //     border-radius: 0px !important;
          //   }

          //   &:focus-within {
          //     border-color: #4ffcb7 !important;
          //   }
          // }

          &__inputComment {
            width: 100%;
            // color:  !important;
            @include customParagraph(14px);
            // border: 1px solid var(--base-input-text-color) !important;
            background: var(--base-input-background-color);
            outline: none !important;
            font-size: 18px;
            border-radius: 12px !important;
            height: 150px;
            border: none !important;
            stroke: none !important;
          }
          &__input_date {
            width: 100%;
            color: var(--base-input-text-color) !important;
            border: 1px solid var(--base-input-text-color) !important;
            background: var(--base-input-text-color);
            outline: none;
            font-size: 18px;
            border-radius: 8px;
            &:focus {
              outline: var(--base-input-text-color) solid 2px !important;
            }
          }
        }
      }

      &__actions {
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        width: 100%;

        &__cancel_btn {
          @include cancelDeactiveBtn();
        }

        &__submit_btn {
          @include activeThemeBotton();
          // gap: 20px;
          // background: #4FFCB7 !important;
          // border-radius: 12px !important;
          // cursor: pointer;
          // text-transform: capitalize !important;
          // width: 100%;
          // border: none;
          // padding: 10px 10px !important;

          // &:hover {
          //     background: #4FFCB7 !important;
          // }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__dialog {
      &__form {
        overflow: auto;
        height: calc(100vh - 180px);

        &__field_container {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          width: 100%;

          &__field {
            width: 100%;

            &__input {
              padding: 10px;
              font-size: 14px;
              border-radius: 3px;
            }

            &__select {
              padding: 10px;
              font-size: 14px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}
