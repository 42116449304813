@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.dialog {
  &__content {
    &__text {
      color: #4ffcb7 !important;
    }
  }

  &__actions {
    &__noButton {
      @include activeThemeBotton();
      width: 80px !important;
    }

    &__yesButton {
      @include activeThemeBotton();
      width: 80px !important;
    }
  }
}
