@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  width: 100%;
  background: rgba(84, 84, 84, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  font-family: "DM Sans";
  padding: 30px !important;

  &__logo {
    @include flexProperties(row, center, center, $fullWidth: true);
    gap: 30px;
    &__img {
      height: 90px !important;
    }

    &__text {
      //   margin-left: 30px !important;
      @include customHeading();
    }
  }
  &__horizontalLine {
    width: 100%;
    background-color: #707070;
    height: 2px;
    opacity: 0.5;
    margin-bottom: 20px;
    margin-top: 20px !important;
  }

  &__form {
    &__main {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 100%;
      gap: 10px;
      margin-top: 40px !important;

      &__field_container {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        width: 100%;
        gap: 10px;

        &__label {
          @include customTags();
        }

        &__input {
          color: #000 !important;
          border-style: none;
          background: #ffffff !important;
          outline: none;
          padding: 10px 15px;
          font-size: 14px;
          border-radius: 12px;
        }
      }

      &__action {
        @include flexProperties(row, center, center, $fullWidth: true);
        width: calc(100%);
        margin-top: 20px;

        &__btn {
          @include activeThemeBotton();
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 466px) {
  .page {
    padding: 20px;
  }
}

@media only screen and (max-width: 386px) {
  .page {
    padding: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .page {
    padding: 10px;
  }
}

@media only screen and (max-width: 365px) {
  .page {
    padding: 8px;
    &__logo {
      &__text {
        font-size: 24px !important;
      }
    }
  }
}
