@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, center, center, $fullWidth: true);
  min-height: 100vh;
  height: 100%;
  width: 100%;

  &__dialog {
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: var(--base-inner-container-background-color) !important;
    border-radius: 12px;
    border: 1px solid var(--base-border-line-color);
    padding: 20px;
    color: var(--base-heading-color);

    &__header {
      &__title {
        @include customHeading(20px);
      }
    }
    &__actions {
      @include flexProperties(row, flex-end, center, $fullWidth: true);
      width: 100%;
      &__cancel_btn {
        @include cancelDeactiveBtn(16px, 10px);
        height: 40px;
        width: 150px;
      }
    }
  }

  &__loader {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: false);
    width: 40px;
    height: 40px;
    margin-top: 8px;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__dialog {
      &__form {
        overflow: auto;
        height: calc(100vh - 180px);

        &__field_container {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          width: 100%;

          &__field {
            width: 100%;

            &__input {
              padding: 10px;
              font-size: 14px;
              border-radius: 3px;
            }

            &__select {
              padding: 10px;
              font-size: 14px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}
