@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 675px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--base-border-line-color);
    padding: 20px;
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      &__icon_back {
        background-color: var(--base-action-icon-background) !important;
        width: 25px !important;
        height: 25px !important;
        svg {
          fill: var(--base-action-icon-color);
          width: 12px;
          height: 12px;
          margin-bottom: 3px !important;
        }
      }
      &__heading {
        color: var(--base-heading-color);
        font-size: 20px !important;
        font-weight: bold !important;
      }
      &__button {
        @include activeThemeBotton();
        // width: 188px !important;
        // height: 32px !important;
        padding: 0px 25px !important;
      }
    }
  }
  &__middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      flex-basis: 16.66%;
    }
  }
  &__conDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 0px 0px 20px;
    &__heading {
      @include customTags();
    }
    &__value {
      @include customValue(20px);
      white-space: nowrap;
    }
  }
  &__tracks {
    margin-top: 20px;
    border-top: 1px solid var(--base-border-line-color);
    &__container {
      padding: 20px;
      &__heading {
        @include customHeading(20px);
      }
    }
  }
  &__table {
    margin-top: 10px !important;
    padding: 0px;
    height: 325px !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &__row {
      color: #fff !important;
    }
  }
}
@media only screen and (max-width: 1535px) {
  .page {
    height: 100%;
  }
}
