@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.modalbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #383e51;
  border-radius: 12px;
  box-shadow: 2px 2px 4px #000000;
  padding: 20px;
  color: #ffffff;
  border: none;
  // border: 1px solid var(--base-border-line-color);

  &__closebutton {
    text-align: right;
    img {
      cursor: pointer;
    }
  }

  &__textfield {
    background-color: #192233;
    border-radius: 12px;
    resize: none;
    width: 60vw;
    color: #fff !important;
    margin-top: 20px !important;
    margin-bottom: 15px !important;

    &:focus {
      outline: none !important;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;

    &__urev {
      margin-right: 5px !important;
      border-radius: 12px !important;
      padding: 15px 20px !important;
    }

    &__cmnt {
      border-radius: 12px !important;
      padding: 15px 20px !important;
    }
  }
}

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;
  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        @include flexProperties(column, center, center, $fullWidth: true);
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;
        border: 1px solid var(--base-border-line-color);

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;
          &__img_title_container {
            @include flexProperties(row, flex-start, center, $fullWidth: true);
            &__image {
              height: 55px;
              width: 55px;
            }
            &__title {
              @include customHeading(24px);
              margin-left: 20px !important;
            }
          }

          &__amendbtn {
            width: 264px !important;
            height: 40px !important;
            @include activeThemeBotton();
          }

          &__apprbtn {
            border-radius: 12px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__header {
            &__img_title_container {
              &__title {
                @include customHeading(20px);
                margin-left: 10px !important;
              }
            }
            &__amendbtn {
              width: 130px !important;
              font-size: 12px !important;
            }
          }
        }
      }
    }
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}
