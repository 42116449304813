@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  width: 100% !important;
  &__bannerGrid {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    width: 100%;

    &__searchBannerContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      width: 100% !important;
      background-color: var(--base-search-add-artist-bg-color);
      background-image: var(--base-search-add-artist-bg-color-shade);
      border-radius: 12px;
      height: 118px !important;
      padding: 20px;
      &__textContainer {
        white-space: nowrap;
        &__text {
          @include customHeading(25px);
        }
      }
      &__searchInput {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .page {
    &__fieldsContainer {
      &__search_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__fieldsContainer {
      &__search_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .page {
    &__searchbanner {
      gap: 30px;
      &__text {
        font-size: 20px !important;
        width: auto;
        white-space: nowrap;
      }
      &__input {
        width: 80%;
        input {
          width: 300px;
        }
      }
      &__searchbtnarea {
        width: auto;
        margin-left: 0px;
        &__searchbtn {
          @include activeThemeBotton();
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .page {
    &__searchbanner {
      gap: 20px;
      &__text {
        font-size: 15px !important;
        width: auto;
        white-space: nowrap;
      }
      &__input {
        width: 40%;
        input {
          width: 300px;
        }
      }
      &__searchbtnarea {
        margin-left: 0px;
        &__searchbtn {
          @include activeThemeBotton();
          width: auto !important;
          padding: 10px 20px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__searchbanner {
      &__input {
        width: 70%;
      }
      &__searchbtnarea {
        display: none;
      }
    }
  }
}
