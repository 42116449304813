@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, center, $fullWidth: true);
  width: 100% !important;
}

// @media only screen and (max-width: 1700px) {
//   .page {
//     flex-wrap: wrap;
//   }
// }
// @media only screen and (max-width: 684px) {
//   .page {
//     &__artist {
//       flex: 1;

//       &__box {
//         min-height: 500px;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 434px) {
//   .page {
//     &__details {
//       &__box {
//         &__adetails {
//           &__content {
//             @include flexProperties(
//               column,
//               flex-start,
//               flex-start,
//               $fullWidth: false
//             );
//             gap: 20px;
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 630px) and (max-width: 1199.98px) {
//   .page {
//     flex-direction: column;
//     align-items: center;
//   }
// }
// @media only screen and (max-width: 600px) {
//   .page {
//     &__details {
//       &__box {
//         &__tracks {
//           &__header {
//             &__title {
//               @include customHeading(20px);
//             }

//             &__search {
//               width: 40%;

//               &__seaarch_icon {
//                 left: 20px;
//               }

//               &__input {
//                 width: 100%;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
