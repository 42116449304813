@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222c41;
    font-size: 20px;
    font-weight: bold;
    background-color: #4ffcb7;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: #222c41 !important;
        color: #4ffcb7 !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: #4ffcb7;
        }
      }
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100%;
      &__tracks {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        &__loader_container {
          @include flexProperties(column, center, center, $fullWidth: true);
          width: 100% !important;
          height: 675px !important;
        }
        &__header {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          width: 100%;
          gap: 10px !important;
          border-bottom: 1px solid #5a7380;
          padding: 20px;
          &__title {
            @include customHeading(24px);
          }
        }

        &__lineOne {
          @include flexProperties(
            row,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          height: 111px !important;
          width: 100% !important;
          border-bottom: 1px solid #5a7380 !important;
          // &__platforms {
          //   @include flexProperties(column, center, flex-start, $fullWidth: false);
          //   width: 26.69%;
          //   height: 100%;
          //   padding: 20px;
          //   border-right: 1px solid #5a7380 !important;
          //   overflow: hidden !important;
          //   &__text {
          //     @include customTags();
          //     white-space: nowrap;
          //     overflow: hidden;
          //     text-overflow: ellipsis;
          //   }
          //   &__socials {
          //     width: 100%;
          //     display: flex;
          //     gap: 7px;
          //     margin-top: 8px !important;
          //     &__icons {
          //       width: 26px;
          //       height: 26px;
          //       display: block;
          //     }
          //   }
          // }

          &__genre {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 17.19%;
            // flex: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__genre_inner {
              // background-color: red;
              display: flex;
              font-size: 10px !important;
              // flex-wrap: wrap;
              gap: 5px;
              margin-top: 8px !important;

              &__chip {
                background-color: #5a7380 !important;
                color: var(--base-heading-color) !important;
                padding: 15px 5px !important;
                cursor: pointer !important;
              }
            }
          }

          &__release_date {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 18.39%;
            width: 100%;

            height: 100%;
            padding: 20px;
            border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &__year_ext {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 18.31%;
            width: 100%;

            height: 100%;
            padding: 20px;
            border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &__yearly {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 46.11%;
            width: 100%;

            height: 100%;
            padding: 20px;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        &__lineTwo {
          @include flexProperties(
            row,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          height: 111px !important;
          width: 100% !important;
          border-bottom: 1px solid #5a7380 !important;

          &__own_perc {
            // width: 19.97%;
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            width: 100%;
            height: 100%;
            padding: 20px;
            border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &__producer {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 19.6%;
            width: 100%;
            height: 100%;
            padding: 20px;
            border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &__isrc_code {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 23.31%;
            width: 100%;
            height: 100%;
            padding: 20px;
            // border-right: 1px solid #5a7380 !important;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &__year_ext {
            @include flexProperties(
              column,
              center,
              flex-start,
              $fullWidth: true
            );
            // width: 37.13%;
            width: 100%;

            height: 100%;
            padding: 20px;
            overflow: hidden !important;

            &__text {
              @include customTags();
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &__text2 {
              @include customHeading(24px);
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        &__stream_container {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          padding: 20px;
          &__header {
            @include flexProperties(
              row,
              space-between,
              center,
              $fullWidth: true
            );
            width: 100%;
            &__heading {
              @include customHeading(24px);
            }
          }

          &__box_container {
            @include flexProperties(
              column,
              space-between,
              flex-start,
              $fullWidth: true
            );
            width: 100%;
            margin-top: 30px;
            gap: 20px !important;
            &__box {
              width: 212px;
              border-radius: 20px;
              background-color: #192233;
              &__header {
                @include flexProperties(
                  row,
                  space-between,
                  center,
                  $fullWidth: true
                );
                padding: 20px;
                &__icon_title_container {
                  @include flexProperties(
                    row,
                    flex-start,
                    center,
                    $fullWidth: true
                  );

                  &__icon {
                    height: 24px;
                    width: 24px;
                  }
                  &__title {
                    margin-left: 10px;
                    @include customParagraph(16px);
                    font-weight: 500 !important;
                  }
                }
                &__icon_container {
                  height: 30px !important;
                  width: 30px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &__menu_icon {
                    height: 2px;
                    width: 10px;
                    cursor: pointer;
                  }
                }
              }

              &__streams_container {
                @include flexProperties(
                  row,
                  flex-start,
                  center,
                  $fullWidth: true
                );
                width: 100%;
                padding: 0px 20px 0px 20px;
                &__title {
                  @include customHeading(24px);
                  // width: 20% !important;
                  margin-right: 20px;
                }
              }

              &__buttom_container {
                width: 100%;
                padding: 0px 20px 0px 20px;
                &__heading {
                  @include customTags();
                }
              }

              &__country_data {
                border-top: 1px solid #5a7380;
                margin-top: 15px;
                overflow-y: scroll;
                height: 400px !important;
                &::-webkit-scrollbar {
                  display: none;
                }
                &__icon_streams {
                  @include flexProperties(
                    column,
                    flex-start,
                    flex-start,
                    $fullWidth: true
                  );
                  width: 100%;
                  padding: 20px;
                  &__inner {
                    @include flexProperties(
                      row,
                      center,
                      center,
                      $fullWidth: true
                    );
                    width: 100%;
                    &__icon {
                      @include customParagraph(16px);
                      margin-right: 10px !important;
                    }
                    &__streams {
                      @include customParagraph(16px);
                      font-weight: 500;
                    }
                  }
                }

                &__graph {
                  @include flexProperties(
                    column,
                    center,
                    center,
                    $fullWidth: true
                  );
                  width: 100% !important;
                }
              }
            }

            // &__spotify {
            // }
          }
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: false);
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}

/* Mobile */
@media screen and (max-width: 360px) {
  /* Your CSS styles for mobile devices with a width up to 360px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;

            &__genre {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__own_perc {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  /* Your CSS styles for devices with a width between 361px and 375px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__genre {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__own_perc {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;

            &__genre {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__own_perc {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 315px) and (max-width: 599px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__genre {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__own_perc {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                margin-bottom: 20px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

/* Tablet */
@media screen and (min-width: 600px) and (max-width: 767px) {
  /* Your CSS styles for tablets with a width between 600px and 767px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            &__genre {
              @include flexProperties(
                column,
                center,
                flex-start,
                $fullWidth: true
              );

              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__yearly {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;

            &__own_perc {
              border-bottom: 1px solid #5a7380;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              // border-bottom: 1px solid #5a7380;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );
              &__box {
                width: 100%;
                margin: 0px 0px 10px 0px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS styles for tablets with a width between 768px and 1024px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;

          &__lineOne {
            @include flexProperties(
              row,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;

            // &__platforms {
            //   &__text {
            //   }
            //   &__socials {
            //     &__icons {
            //     }
            //   }
            // }
          }

          &__lineTwo {
            @include flexProperties(
              row,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );
              width: 100%;
              &__box {
                width: 100%;
                margin-bottom: 20px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1279px) {
  /* Your CSS styles for tablets with a width between 800px and 1280px */
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  /* Your CSS styles for tablets with a width between 1024px and 1366px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 675px !important;
          &__stream_container {
            &__box_container {
              &__box {
                width: max-content;
              }
            }
          }
        }
      }
    }
  }
}

/* Laptop */
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  /* Your CSS styles for laptops with a width between 1280px and 1366px */
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  /* Your CSS styles for laptops with a width between 1367px and 1920px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin-right: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Desktop */
@media screen and (min-width: 1921px) and (max-width: 2559px) {
  /* Your CSS styles for desktops with a width between 1921px and 2560px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin: 0px 20px 0px 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2560px) and (max-width: 3840px) {
  /* Your CSS styles for desktops with a width between 2561px and 3840px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__lineOne {
            &__genre {
              &__text {
                @include customTags();
              }
            }

            &__release_date {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }

            &__year_ext {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }

            &__yearly {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }
          }

          &__lineTwo {
            &__own_perc {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }

            &__producer {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }

            &__isrc_code {
              &__text {
                @include customTags();
              }
              &__text2 {
                @include customHeading(30px);
              }
            }
          }

          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin-right: 20px;

                &__streams_container {
                  &__title {
                    margin-top: -10px;
                    @include customHeading(30px);
                  }
                }

                &__buttom_container {
                  &__heading {
                    @include customTags();
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
