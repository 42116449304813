@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, center, center, $fullWidth: false);
  gap: 10px;
  // margin-right: 5px;

  &__message {
    position: relative;
    margin-top: 10px;
    cursor: pointer;

    &__bubble {
      position: absolute;
      width: 8px;
      height: 8px;
      // background: $secondary-bg-color-2;
      border-radius: 50%;
      top: -4px;
      right: -4px;
    }
  }

  &__user {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    text-align: left;

    &__name {
      @include customHeading(18px);
      font-weight: 700 !important;
      @include textEllipsis();
    }

    &__role {
      @include customTags();
    }
  }

  &__profile_notification {
    @include flexProperties(row, flex-start, flex-start, $fullWidth: false);
    gap: 20px;

    &__bell {
      @include flexProperties(row, center, center, $fullWidth: false);
      position: relative;
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      width: 40px;

      &__bubble {
        position: absolute;
        height: 15px;
        width: 15px;
        color: #4ffcb7;
        // background: $secondary-bg-color-2;
        border-radius: 50%;
        font-size: 10px;
        text-align: center;
        top: -3px;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .page {
    &__profile_notification {
      @include flexProperties(
        row-reverse,
        flex-start,
        flex-start,
        $fullWidth: false
      );
    }
  }
}

@media only screen and (max-width: 440px) {
  .page {
    gap: 15px;

    &__message {
      &__icon {
        height: 22px;
      }
    }

    &__profile_notification {
      @include flexProperties(
        row-reverse,
        flex-start,
        center,
        $fullWidth: false
      );
      gap: 15px;

      &__bell {
        height: 30px;
        width: 30px;

        &__icon {
          height: 16px;
        }

        &__bubble {
          height: 10px;
          width: 10px;
          font-size: 8px;
        }
      }
    }
  }
}
@media only screen and (max-width: 340px) {
  .page {
    gap: 10px;

    &__message {
      margin-top: 5px;
      &__icon {
        height: 16px;
      }
      &__bubble {
        top: 1px;
      }
    }

    &__profile_notification {
      @include flexProperties(
        row-reverse,
        flex-start,
        center,
        $fullWidth: false
      );
      gap: 10px;

      &__bell {
        height: 30px;
        width: 30px;

        &__icon {
          height: 16px;
        }

        &__bubble {
          height: 10px;
          width: 10px;
          font-size: 8px;
        }
      }
    }
  }
}
