@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  text-align: center;
  color: #fff;
  padding: 30px;
  background-color: #222c41;
  border-radius: 12px;
}
