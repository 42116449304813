@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  font-family: "DM Sans";
  &__stepper_btn_wrapper {
    &__steps {
      background-color: #222c41;
      border: 1px solid #4ffcb7;
      border-radius: 10px;
      padding: 8px 8px !important;
      width: 20%;
      text-align: center;
      color: #4ffcb7 !important;
    }
  }

  &__return_home_btn {
    background: #4ffcb7 !important;
    color: #222c41 !important;
    border-radius: 12px !important;
    font-size: 16px;
    font-weight: 700 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    width: 100%;
    border: none;

    // &:hover {
    //   background: #4ffcb7 !important;
    //   color: #fff !important;
    // }
  }

  &__download_copy {
    // @include activeThemeBotton();
    padding: 0px 10px;
  }

  &__back_btn {
    text-transform: capitalize;
    border-radius: 12px !important;
    background-color: transparent;
    border: 1px solid #e12020 !important;
    color: #e12020 !important;
    font-weight: bold !important;
    padding: 0px 20px !important;
    min-width: 100px !important;

    &:hover {
      background: transparent !important;
      color: #e12020 !important;
    }
  }
  &__loader {
    @include flexProperties(column, center, center, $fullWidth: false);
    width: 50px;
    height: 50px;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
