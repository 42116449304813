@import "./styles/colors.scss";
@import "./styles/scss.scss";
@font-face {
  font-family: "NewTitle";
  src: local("NewTitle-Bold"),
    url("../src/fonts/NewTitle-Bold.ttf") format("truetype");
}

.App {
  text-align: center;
}
.cMuiDrawer-docked.MuiDrawer-paper {
  border: none !important;
}

// =========== MUI table pagination style =================
.MuiToolbar-root.MuiTablePagination-toolbar {
  border-top: 1px solid var(--base-border-line-color);
  padding-top: 10px;
  margin-top: 10px;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  // background: var(--base-inner-container-background-color) !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-pagination-actions .MuiIconButton-root:last-child svg,
.custom-pagination-actions
  .MuiButtonBase-root.MuiIconButton-root:first-child
  svg {
  color: var(--base-action-icon-color);
  background-color: var(--base-action-icon-background);
  border-radius: 50%;
  padding: 4px !important;
  width: 30px !important;
  height: 30px !important;
}
.custom-pagination-actions .MuiButtonBase-root.Mui-disabled:first-child svg,
.custom-pagination-actions .MuiButtonBase-root.Mui-disabled:last-child svg {
  color: var(--base-action-disable-icon-color);
  background-color: var(--base-action--disable-icon-background);
  border-radius: 50%;
  padding: 4px !important;
  width: 30px !important;
  height: 30px !important;
}
.custom-select-style {
  color: var(--base-heading-color) !important;
  // font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "DM Sans" !important;
  letter-spacing: 1px !important;
}
.custom-select {
  background-color: var(--base-button-background-color) !important;
  color: var(--base-container-backgroung-color) !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-family: "DM Sans" !important;
  font-weight: 700 !important;
}

// =========== MUI table pagination style End =================

.MuiSvgIcon-root.MuiSelect-icon {
  color: var(--base-action-icon-color) !important;
}

.apexcharts-text.apexcharts-yaxis-label,
.apexcharts-text.apexcharts-xaxis-label {
  color: white !important;
  fill: white !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background-color: #222c41 !important;
}
.apexcharts-tooltip-title {
  background-color: #222c41 !important;
}
.apexcharts-menu {
  background-color: #222c41 !important;
}
.apexcharts-menu:hover .apexcharts-menu-item {
  background-color: #222c41 !important;
}
.apexcharts-zoom-icon,
.apexcharts-reset-icon,
.apexcharts-pan-icon {
  display: none !important;
  color: white !important;
  background-color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
  display: none;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-top {
  width: 80% !important;
  left: 10% !important;
}
.received {
  border: 2px solid #4ffcb7 !important;
}
.offline {
  border: none;
}

.MuiStepConnector-line.MuiStepConnector-lineHorizontal.MuiStepConnector-line {
  border-color: #4ffcb7 !important;
}
.ck {
  // background-color: #222c41;
  color: #222c41;
}
.MuiList-root-MuiMenu-list {
  background-color: #192233 !important;
  font-weight: bold !important;
}
.MuiList-root.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiInputBase-inputAdornedEnd {
  -webkit-text-fill-color: rgba(140, 140, 140, 0.7) !important;
}
@media only screen and (max-width: 600px), (max-width: 320px) {
  .MuiTabs-flexContainer.MuiTabs-flexContainer {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 10px;
  }
  .graphToolsHide {
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon {
      display: none;
    }
  }
}
@media only screen and (max-width: 320px) {
  .MuiTabs-flexContainer.MuiTabs-flexContainer {
    width: 200px;
  }
}

// ? view funcond dashboard tablist
// .custom-root .Mui-selected {
//   background-color: transparent !important;
//   color: var(--base-heading-color) !important;
//   &::after {
//     content: "";
//     display: block;
//     height: 5px;
//     border-radius: 2px;
//     background-color: var(--base-button-background-color);
//     bottom: 0px;
//     position: absolute;
//     width: 90%;
//     transition: all 0.9s ease-in-out;
//   }
// }
// .custom-root .MuiTabs-indicator {
//   background-color: transparent !important;
// }

// ? React Toastify
.Toastify__toast--error {
  // border: 1px solid #eb5757;
  border-radius: 12px !important;
  background: var(--base-error-toast-color) !important;
}
.Toastify__toast--error::after {
}
.Toastify__toast--error::before {
}
.Toastify__toast--success {
  border-radius: 12px !important;
  background: var(--base-success-toast-color) !important;
}
.Toastify__toast--success::before {
}
.Toastify__toast--sucess::after {
}
.Toastify__toast--warning {
  border-radius: 12px !important;
  background: var(--base-warning-toast-color) !important;
}
.Toastify__toast--warning::before {
}
.Toastify__toast--warning::after {
}
.Toastify__toast-body {
  color: var(--base-toast-font-color) !important;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}
// .Toastify__toast > button > svg {
//   display: none;
// }
.Toastify__close-button {
  color: var(--base-close-icon-toast-color) !important;
  margin-top: 15px !important;
  margin-right: 10px;
}
