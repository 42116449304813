.gradient_button {
    width: 200px;
    padding: 10px 35px;
    border-radius: 50px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    cursor: pointer;
    background: linear-gradient(45deg, #EC1086, #36A1FF);
}