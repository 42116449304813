@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  // input[type="number"] {
  //   -moz-appearance: textfield;
  // }

  &__details {
    &__box {
      margin-top: 40px;
      &__tracks {
        width: 100%;
        background-color: white !important;
        border-radius: 12px;
        padding: 50px;
        &__contract {
          text-align: justify;
          padding: 20px;
          height: 330px;
          overflow-y: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none;
          margin-top: 20px;
          color: #fff;
          border-radius: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}
