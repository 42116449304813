// ? Modal css
.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-title-font-color);
    font-size: 20px;
    font-weight: bold;
    background-color: var(--base-title-bg-color);
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: var(--base-title-font-color) !important;
        color: var(--base-title-bg-color) !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: var(--base-title-bg-color);
        }
      }
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}

@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
