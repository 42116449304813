@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 694px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--base-border-line-color);
    padding: 20px;

    &__heading {
      color: var(--base-heading-color);
      font-size: 20px !important;
      font-weight: bold !important;
    }
    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include activeThemeBotton();
      width: 205px !important;
      height: 32px !important;
    }
    &__req_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include activeThemeBotton();
      width: 78px !important;
      height: 32px !important;
    }
    &__select {
      &__icon {
        color: var(--base-action-icon-color);
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
        cursor: pointer;
      }
      &__menuItem {
        font-size: 12px !important;
        font-weight: 700 !important;
      }
    }
  }
  &__table {
    margin-top: 10px !important;
    padding: 0px 20px 0px 20px;
    height: 580px !important;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__topHeadHeadings {
    @include customTags();
  }
  &__name {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading1 {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading2 {
    @include customHeading(16px);
    font-weight: 700 !important;
  }
  &__actionIcon {
    background-color: var(--base-action-icon-background) !important;
    width: 30px;
    height: 30px;
    svg {
      fill: var(--base-action-icon-color);
      width: auto;
      height: 16px;
    }
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
  &__button {
    @include activeThemeBotton();
    margin-top: 20px !important;
  }
  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }
  &__noDataContainer {
    @include flexProperties(row, center, center, $fullWidth: true);
    height: 100%;
    gap: 20px;
    &__heading1 {
      @include customHeading(20px);
    }
    &__heading2 {
      @include customParagraph(16px);
    }
  }
  &__reqPaymentContainer {
    @include flexProperties(column, space-between, center, $fullWidth: true);
    height: 619px !important;
    width: 100% !important;
    padding: 20px;

    &__fieldsContainer {
      width: 100%;
      &__topHeading {
        @include customTags();
        margin-bottom: 10px;
      }
      &__buttonMsg {
        font-size: 12px;
        color: #ff3d81;
        margin-top: 10px;
      }
    }
    &__BtnContainer {
      width: 100%;
      &__ReqPayBtn {
        @include activeThemeBotton();
      }
    }
  }
  &__amountTextFiield {
    height: 286px !important;
  }
}

.invite {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 310px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--base-border-line-color);
    padding: 20px;

    &__heading {
      color: var(--base-heading-color);
      font-size: 20px !important;
      font-weight: bold !important;
    }
    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include activeThemeBotton();
      width: 205px !important;
      height: 32px !important;
    }
    &__req_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include activeThemeBotton();
      width: 78px !important;
      height: 32px !important;
    }
    &__select {
      &__icon {
        color: var(--base-action-icon-color);
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
        cursor: pointer;
      }
      &__menuItem {
        font-size: 12px !important;
        font-weight: 700 !important;
      }
    }
  }
  &__reqPaymentContainer {
    @include flexProperties(column, space-between, center, $fullWidth: true);
    height: 229px !important;
    width: 100% !important;
    padding: 20px;

    &__fieldsContainer {
      width: 100%;
      &__topHeading {
        @include customTags();
        margin-bottom: 10px;
      }
      &__buttonMsg {
        font-size: 12px;
        color: #ff3d81;
        margin-top: 10px;
      }
    }
    &__BtnContainer {
      width: 100%;
      &__ReqPayBtn {
        @include activeThemeBotton();
      }
    }
  }
}
