@import "../../../../styles/scss.scss";
@import "../../../../styles/colors.scss";

.page {
  &__table {
    background-color: var(--base-container-backgroung-color) !important;
    overflow: hidden !important;
    padding: 0px 20px;
    margin-top: 20px;
    &__avatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    &__track_name {
      @include customHeading(15px);
      @include textEllipsis(150px);
    }
  }
  height: 100%;
  &__header {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__seachBar {
      position: relative;
      .searchIcon {
        color: var(--base-button-background-color);
        position: absolute;
        top: 10px;
        left: 8px;
        z-index: 9999;
      }
    }

    &__search {
      border: 2px solid var(--base-border-line-color);
      background-color: #202632;
      padding: 10px 30px 10px 35px;
      border-radius: 10px !important;
      outline: none !important;
      color: var(--base-input-text-color);
      font-size: 14px;
      &::placeholder {
        color: var(--base-heading-color);
        font-size: 14px;
      }
    }

    &__heading {
    }

    &__btn_container {
      min-width: 300px;

      &__btn {
        @include activeThemeBotton();

        padding: 10px !important;
        height: 40px;
        text-transform: capitalize !important;
      }

      &__btn_pink {
        @include activeThemeBotton();

        padding: 10px !important;
        height: 40px;
        text-transform: capitalize !important;
      }
    }
  }

  &__caption {
    margin-top: 20px;
  }

  // &__btn {
  //   @include activeThemeBotton();

  //   background-color: transparent !important;
  //   border: 1px solid #951057 !important;
  //   width: auto;
  //   height: 40px;
  //   text-transform: capitalize !important;

  //   &:hover {
  //     background: #951057 !important;
  //     &__button {
  //       border-radius: 25px !important;
  //       background: var(--base-button-background-color) !important;
  //       padding: 7px 30px !important;
  //       color: var(--base-heading-color) !important;

  //     }

  //     &__search {
  //       background: transparent;
  //       border: 2px solid #a9a9a9;
  //       outline: none;
  //       padding: 10px;
  //       color: var(--base-button-background-color);
  //       border-radius: 5px;
  //     }
  //   }
  // }
}
.searchBarMob {
  height: 40px !important;
  width: 100%;
  display: none;
}
@media only screen and (max-width: 440px) {
  .page {
    &__header {
      @include flexProperties(column-reverse, center, center, $fullWidth: true);
      gap: 20px;
      // &__button {
      //   width: 100%;
      // }

      &__search {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .page {
    &__header {
      right: 3px;
      top: -5px;
      // &__seachBar {
      // }
    }
    .searchBarMob {
      display: block;
    }
  }
}
