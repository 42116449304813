@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  background: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  height: 834px !important;
  width: 100% !important;
  padding: 20px !important;
  overflow: hidden;
}
