@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  // input[type="number"] {
  //   -moz-appearance: textfield;
  // }

  &__fieldsContainer {
    &__form {
      padding-bottom: 40px;

      &__formfield {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          gap: 10px;
          color: var(--base-tags-color);
          // margin-left: 10px;
          font-size: 12px;
          text-transform: uppercase;
          padding: 5px;
          &__image {
            height: 18px;
            width: 18px;
          }
        }

        &__input {
          color: #fff !important;
          border: 1px solid var(--base-input-focus-color) !important;
          background: var(--base-container-backgroung-color);
          outline: none;
          padding: 15px;
          font-size: 14px;
          border-radius: 8px;
          width: 100%;
          // max-width: 100%;
          min-width: 100%;
        }

        &__button {
          @include activeThemeBotton();
          padding: 10px 30px !important;
          width: 100% !important;
        }

        &__remove {
          color: var(--base-icon-color) !important;
          cursor: pointer;
          margin-top: 10px;
          transform: translateX(-50px);
        }
      }

      &__list {
        p {
          color: #fff !important;
        }
        &:hover {
          background-color: var(--base-hover-list-color) !important;
        }
      }
    }
  }

  &__details {
    &__box {
      &__tracks {
        width: 100%;
        padding: 20px 0px;
      }
    }
  }
  &__select_icon {
    color: var(--base-icon-color) !important;
    font-size: 20px;
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
  }
}

@media only screen and (max-width: 434px) {
  .page {
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
  }
}
