@import "../../styles/colors.scss";

.page {
  width: 100%;
  &__topSec {
    &__greeting {
      font-size: 12px !important;
      color: var(--base-tags-color) !important;
      font-weight: 500 !important;
    }
    &__userName {
      font-size: 28px !important;
      color: var(--base-heading-color) !important;
      font-weight: bold !important;
    }
  }
  &__boxes {
    display: flex;
    align-items: center;
    margin-top: 19px;
    gap: 20px;
    flex-wrap: wrap;
    &__box {
      background-color: var(--base-container-backgroung-color);
      border-radius: 12px;
      border: 1px solid var(--base-border-line-color);
      width: 163px;
      height: 129px;
      padding: 20px 20px 21px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__icon {
        svg {
          fill: var(--base-icon-color) !important;
          width: 18px;
          height: 18px;
        }
      }
      &__num {
        font-size: 28px !important;
        color: var(--base-heading-color) !important;
        font-weight: bold !important;
        margin: 3px 0px;
      }
      &__dealName {
        font-size: 12px !important;
        color: var(--base-tags-color) !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
