@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  padding: 30px 60px;

  &__logo {
    @include flexProperties(row, space-between, center, $fullWidth: true);
    gap: 30px;

    &__text {
      font-weight: bold;
    }
  }

  &__form {
    &__main {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 100%;
      gap: 10px;

      &__field_container {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        width: 100%;
        gap: 10px;

        &__label {
          color: #4ffcb7;
        }

        &__input {
          width: auto;
          color: #c2c2c2 !important;
          border: 1px solid #4ffcb7 !important;
          background: transparent !important;
          outline: none;
          padding: 15px;
          font-size: 18px;
          border-radius: 8px;
        }
      }

      &__form_action {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        width: 100%;
      }

      &__action {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        flex-wrap: nowrap;
        gap: 20px;
        width: 100%;
        margin-top: 20px;

        &__btn {
          @include activeThemeBotton();

          &:hover {
            background: #4ffcb7 !important;
          }
        }

        &__login_back_btn {
          @include activeThemeBotton();
        }
      }
    }
  }

  &__social_media {
    margin-top: 20px;

    &__button {
      padding: 5px;
      height: 100%;
      background: "#FFFFFF";
      width: 100%;
      cursor: pointer;

      &__img {
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 466px) {
  .page {
    padding: 20px;
  }
}

@media only screen and (max-width: 386px) {
  .page {
    padding: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .page {
    padding: 20px;

    &__logo {
      &__text {
        font-size: 22px !important;
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  .page {
    padding: 20px;

    &__logo {
      &__text {
        font-size: 18px !important;
      }
    }
  }
}

@media only screen and (max-width: 326px) {
  .page {
    padding: 20px;

    &__logo {
      gap: 10px;
      margin-bottom: 10px;

      &__img {
        height: 80px;
      }

      &__text {
        font-size: 16px !important;
      }
    }
  }
}
