@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  height: 675px !important;
  max-height: 675px !important;
  width: 100% !important;
  background: var(--base-container-backgroung-color);
  border: 1px solid var(--base-border-line-color);
  padding: 20px;
  border-radius: 12px;

  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__heading {
      color: var(--base-heading-color);
      font-size: 20px !important;
      font-weight: bold !important;
    }
    &__select {
      &__icon {
        color: var(--base-action-icon-color);
        font-size: 30px;
        font-weight: bold;
        margin-right: 10px;
        cursor: pointer;
      }
      &__menuItem {
        font-size: 12px !important;
        font-weight: 700 !important;
      }
    }
  }
  &__table {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__topHeadHeadings {
    @include customTags();
  }

  &__name {
    @include textEllipsis(120px);
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading1 {
    @include textEllipsis(100px);
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading2 {
    @include customHeading(16px);
    font-weight: 700 !important;
  }

  &__actionIcon {
    background-color: var(--base-action-icon-background) !important;
    width: 30px;
    height: 30px;
    svg {
      fill: var(--base-action-icon-color);
      width: auto;
      height: 16px;
    }
  }

  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }

  &__button {
    @include activeThemeBotton();
    margin-top: 20px !important;
  }

  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }

  &__skeletonLoader {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    @include flexProperties(row, space-between, center, $fullWidth: false);
    height: 40px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
    &__title {
      @include customHeading(24px);
    }
    &__search {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: var(--base-search-container-background);
      // background: red;
      border-radius: 12px;
      padding: 21px 10px 21px 15px;
      height: 40px;
      &__seaarch_icon {
        color: var(--base-search-container-icon-color);
      }
      &__input {
        // background: var(--base-search-container-background);
        background: transparent;
        outline: none;
        color: var(--base-input-text-color);
        border-radius: 12px;
        border: none;
        font-size: 14px;
        margin-left: 10px;
        &::placeholder {
          color: var(--base-heading-color);
          // opacity: 0.5;
        }
      }
    }
  }

  &__tableContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__placement {
    @include customHeading(24px);
    @include textEllipsis(150px);
    cursor: pointer;
    :hover {
      color: var(--base-hover-color);
    }
    &__artist_image {
      height: 55px !important;
      width: 55px !important;
    }
  }

  &__country {
    @include customParagraph(14px);
    @include textEllipsis(150px);
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__email {
    @include customParagraph(14px);
    @include textEllipsis(120px);
    text-decoration: underline;
  }

  &__yearly_earning {
    @include customHeading(18px);
  }

  &__topHeadHeadings {
    @include customTags();
  }

  &__icon {
    svg {
      fill: var(--base-action-icon-color) !important;
      width: auto;
      height: 16px;
    }
  }

  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
  &__button {
    @include activeThemeBotton();
    margin-top: 20px !important;
  }
  &__skeletonLoader {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }
}
