@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, center, $fullWidth: true);
  width: 100% !important;
  height: 100% !important;

  &__list {
    width: 100% !important;
    height: 100% !important;
  }
}
.table {
  height: 100% !important;
  width: 100% !important;
  background: var(--base-container-backgroung-color) !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &__head {
    position: sticky;
    z-index: 1;
  }

  &__col {
    background: #222c41 !important;
    text-align: left !important;
    border-bottom: 1px solid #474d5e;
    @include textEllipsis();
    &__heading {
      @include customTags();
    }
    &__borderClass {
      border-radius: 12px !important;
      background-color: #d6d6d6;
    }

    &__last {
      color: white !important;
      background: #222c41 !important;
      text-align: center !important;
    }
    &__lastdays {
      color: rgb(145, 144, 144) !important;
      background: #222c41 !important;
      text-align: center !important;
      font-size: 9px;
    }
  }

  &__row {
    border-bottom: 0px !important;
    background-color: #192233;
    // height: 53px !important ;
    &__artist_name_container {
      width: 150px !important;
      max-width: 150px !important;
      @include textEllipsis();
    }
    &__artist_name {
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 700;
      color: #fff;
    }
    &__submitOnText {
      @include textEllipsis();
      color: #fff;
      text-decoration: underline;
      width: 50px;
      max-width: 50px;
    }

    &__green {
      color: green;
    }

    &__red {
      color: red;
    }

    &__icon {
      cursor: pointer;
    }

    &__artistname {
      // font-weight: bold;
      font-size: 16px;
    }
    &__href {
      text-decoration: none;
    }
    &__href:hover {
      color: palevioletred;
    }
  }

  &__label {
    @include flexProperties(row, flex-start, center, $fullWidth: true);
    gap: 10px;
    color: white;
    margin-bottom: 10px;
    font-size: 1rem;
    font-family: "DM Sans";
  }

  &__bank_info_wrapper {
    background-color: #141414;
    padding: 15px;
    border-radius: 8px;
    margin-top: 8px;
    overflow-y: auto;
    width: 147px !important;
    max-width: 147px !important;
    max-height: 147px !important;
    height: 147px !important;

    &__name {
      font-size: 1rem;
      font-family: "DM Sans";
      color: #fff;
    }
    &__label {
      gap: 0;
      font-size: 1rem;
      font-family: "DM Sans";
      color: #fff;
      font-weight: 500;
    }
    &__input {
      background: #192233;
      color: #fff;
      font-size: 0.875rem;
      border: none;
      margin-bottom: 7px;
    }
    &__image {
      width: 30%;
      height: auto;
      &__downloadbtn {
        @include activeThemeBotton();
        margin-top: 20px !important;
        width: 147px !important;
        max-width: 147px !important;
      }
    }
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
    }
    p {
      margin: 0px;
    }
  }

  &__image_container {
    width: 90px !important;
    max-width: 90px !important;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  .table {
    // &__col {

    // }
    overflow-x: scroll;

    &__row {
      &__sosi {
        width: 100%;
      }
    }
  }
}
