@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__banner {
    background: #192233;
    width: calc(100%);
    // width: calc(100% );
    // min-height: 10.8rem;
    border-radius: 12px;
    overflow: hidden;
    // padding: 40px;

    &__conatiner {
      @include flexProperties(column, center, flex-start, $fullWidth: true);
      gap: 20px;
      width: 100%;

      &__button_wrapper {
        @include flexProperties();
        gap: 10px;
        &__button {
          @include activeThemeBotton();
          // @include fontSize(14px);
          flex-wrap: wrap;
          padding: 2px 15px !important;
        }
      }
    }
  }
}
