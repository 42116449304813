@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, center, center, $fullWidth: true);
  width: 100%;
  padding-top: 20px !important;
  &__table {
    background: var(--base-container-backgroung-color) !important;
    overflow: scroll;
    // overflow-x: hidden !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    border-collapse: separate !important;
    border-spacing: 4px !important;
    min-height: 574px !important;
    max-height: 574px !important;
    width: 100% !important;
    &::-webkit-scrollbar {
      display: none;
    }

    &__head {
      background-color: var(--base-container-backgroung-color) !important;
      position: sticky;
      z-index: 1 !important;
    }

    &__brow {
      background-color: var(--base-container-backgroung-color) !important;
      width: 100% !important;
      tr {
        &:nth-child(even) {
          background-color: var(
            --base-inner-container-background-color
          ) !important;
        }

        &:nth-child(odd) {
          background-color: var(
            --base-inner-container-background-color
          ) !important;
        }
      }
    }

    &__col1 {
      background: var(--base-container-backgroung-color) !important;
      text-align: left !important;
      width: 50px !important;
      border-bottom: 1px solid var(--base-border-line-color) !important;
      @include textEllipsis();
      &__heading {
        @include customTags();
      }
    }

    &__col {
      background: var(--base-container-backgroung-color) !important;
      text-align: left !important;
      width: 50px !important;
      border-bottom: 1px solid var(--base-border-line-color) !important;
      @include textEllipsis();
      &__heading {
        @include customTags();
      }
    }

    &__row {
      border-bottom: 0px !important;
      background-color: var(--base-inner-container-background-color) !important;
      @include textEllipsis(1);
      &__filename {
        @include customParagraph(16px);
        @include textEllipsis(200px);
      }

      &__date {
        @include customParagraph(16px);
        text-decoration: underline;
      }

      &__rep_name {
        @include customParagraph(16px);
        text-decoration: underline;
      }

      &__version {
        @include customParagraph(16px);
      }

      &__action2 {
        justify-content: center;
        display: flex;
      }
    }

    &__notes_row {
      background-color: var(--base-container-backgroung-color);
      text-align: left !important;
      width: 200px !important;
      max-width: 200px !important;
      border-bottom: 3px solid var(--base-container-backgroung-color) !important;
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__notes {
    flex: 1;
    width: 100% !important;

    &__notebox {
      width: 100% !important;
      background: var(--base-inner-container-background-color);
      border-radius: 12px;
      padding: 20px;
      &__chips {
        display: flex;
        justify-content: space-between;
        &__label_chip {
          background-color: var(--base-button-background-color) !important;
          max-width: 120px !important;
          @include customHeading(12px);
          color: #000000 !important;
        }
        &__moment_chip {
          background-color: var(--base-button-background-color) !important;
          @include customHeading(12px);
          color: #000000 !important;
        }
      }

      &__notetext {
        @include customParagraph(14px);
      }
    }

    &__noteboxControls {
      @include flexProperties(column, center, center, $fullWidth: true);
      flex-direction: column !important;
      gap: 15px;
    }

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;
          margin-bottom: 20px;

          &__title {
            color: #4ffcb7;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: capitalize;
          }

          &__Editbtn {
            background-color: #4ffcb7 !important;
            border-radius: 50% !important;
            width: 50px;
            height: 50px;
            padding: 0px;
            min-width: 50px !important;
          }
        }
      }

      &__tracks {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: #4ffcb7;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 25px !important;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
            }
          }
        }
      }
    }
  }
}

.notebox.note {
  overflow: visible !important;
}

.note::before {
  content: "";
  width: 120%;
  height: 15px;
  background-color: var(--base-container-backgroung-color);
  position: absolute;
  top: -15px;
  left: -30px;
  z-index: 2;
}

.modalbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  box-shadow: 2px 2px 4px #192233;
  padding: 20px;
  color: #ffffff;
  border: none;
  &__top_heading {
    @include customHeading(20px);
  }

  &__closebutton {
    text-align: right;
    img {
      cursor: pointer;
    }
  }

  &__textfield {
    background-color: #192233;
    border-radius: 12px;
    resize: none;
    width: 60vw;
    color: #fff !important;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
    &:focus {
      outline: none !important;
    }
    &:active {
      outline: none !important;
    }
  }

  &__button {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100% !important;
    &__container {
      @include flexProperties(row, flex-end, center, $fullWidth: true);
      width: 100% !important;
    }

    &__urev {
      @include activeThemeBotton();
      font-size: 13px !important;
      margin-right: 20px !important;
      width: auto !important;
      padding: 10px 30px !important;
    }

    &__cmnt {
      @include activeThemeBotton();
      font-size: 13px !important;
      width: auto !important;
      padding: 10px 30px !important;
    }
  }
}

@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  //   .table {
  //     // &__col {

  //     // }
  //     overflow-x: scroll;

  //     &__row {
  //       &__sosi {
  //         width: 100%;
  //       }
  //     }
  //   }
}
