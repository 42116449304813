@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  background-color: var(--base-background-color);
  background-image: var(--base-backgroundImage-url);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  width: 100% !important;

  &__navbar {
    background: transparent !important;
    height: 100px;
    box-shadow: none;
    width: 5% !important;
    left: 0 !important;
    right: auto !important;

    &__container {
      @include flexProperties(row, space-between, center, $fullWidth: true);

      &__main {
        @include flexProperties(row, flex-start, center, $fullWidth: false);
        gap: 20px;

        &__logo {
          height: 45px;
          margin-top: 10px;
        }
      }
    }
  }

  &__box {
    width: 100%;
  }

  &__footer {
    margin-top: 20px;
    height: 80px;
    width: 100%;
    color: #4ffcb7;
    text-align: center;
    padding-block: 10px;
    margin: 0;
    z-index: 9999;
  }
}

@media only screen and (max-width: 440px) {
  .page {
    &__navbar {
      &__container {
        &__main {
          gap: 10px;

          &__logo {
            height: 33px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) {
  .page {
    &__box {
      width: calc(100% - 200px) !important;
    }
  }
}

@media only screen and (max-width: 440px) {
  .page {
    &__navbar {
      &__container {
        &__main {
          gap: 1px;

          &__logo {
            height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 340px) {
  .page {
    &__navbar {
      &__container {
        &__main {
          gap: 1px;

          &__logo {
            height: 22px;
          }
        }
      }
    }
  }
}
