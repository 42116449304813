@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(column, flex-start, center, $fullWidth: true);
  height: 675px !important;
  max-height: 675px !important;
  width: 100% !important;
  background: var(--base-container-backgroung-color);
  border: 1px solid var(--base-border-line-color);
  padding: 20px;
  border-radius: 12px;

  &__container {
    width: 100% !important;
    height: 600px !important;
    max-height: 600px !important;
    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      width: 100%;
      margin-bottom: 18px !important;

      &__title {
        @include customHeading(24px);
      }
      &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--base-search-container-background);
        // background: red;
        border-radius: 12px;
        padding: 21px 10px 21px 15px;
        height: 40px;
        &__seaarch_icon {
          color: var(--base-search-container-icon-color);
        }
        &__input {
          // background: var(--base-search-container-background);
          background: transparent;
          outline: none;
          color: var(--base-input-text-color);
          border-radius: 12px;
          border: none;
          font-size: 14px;
          margin-left: 10px;
          &::placeholder {
            color: var(--base-heading-color);
            // opacity: 0.5;
          }
        }
      }
    }

    &__list {
      width: 100% !important;
      height: 100% !important;
      &__table {
        height: 95% !important;
        width: 100% !important;
        background: var(--base-container-backgroung-color) !important;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        &__head {
          background-color: var(--base-container-backgroung-color) !important;
          position: sticky;
          z-index: 9 !important;
        }

        &__brow {
          tr {
            &:nth-child(even) {
              background-color: var(
                --base-inner-container-background-color
              ) !important;
            }

            &:nth-child(odd) {
              background-color: var(
                --base-inner-container-background-color
              ) !important;
            }
          }
        }

        &__col {
          background: var(--base-container-backgroung-color) !important;
          text-align: left !important;
          width: 50px !important;
          border-bottom: 1px solid var(--base-border-line-color) !important;
          @include textEllipsis();
          &__heading {
            @include customTags();
          }
        }

        &__no_contracts {
          @include customHeading();
        }

        &__row {
          border-bottom: 0px !important;
          background-color: var(
            --base-inner-container-background-color
          ) !important;
          @include textEllipsis(1);
          &__artistname {
            @include customHeading(24px);
            &__href {
              @include customHeading(24px);
              text-decoration: none;
            }
          }
          &__date {
            @include customParagraph(14px);
            text-decoration: underline;
          }
          &__rep_name {
            @include customParagraph(14px);
            text-decoration: underline;
            text-transform: capitalize;
          }
          &__status {
            &__cip {
              @include customParagraph(14px);
              color: var(--base-paragraph-color) !important;
              border-color: transparent !important;
            }
          }
        }
      }
    }
  }
}

// .table {
//   background: var(--base-container-backgroung-color) !important;
//   overflow: scroll;
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   &__head {
//     background-color: var(--base-container-backgroung-color) !important;
//     position: sticky;
//     z-index: 9 !important;
//   }

//   &__brow {
//     tr {
//       &:nth-child(even) {
//         background-color: var(
//           --base-inner-container-background-color
//         ) !important;
//       }

//       &:nth-child(odd) {
//         background-color: var(
//           --base-inner-container-background-color
//         ) !important;
//       }
//     }
//   }

//   &__col {
//     background: var(--base-container-backgroung-color) !important;
//     text-align: left !important;
//     width: 50px !important;
//     border-bottom: 1px solid var(--base-border-line-color) !important;
//     @include textEllipsis();
//     &__heading {
//       @include customTags();
//     }
//   }

//   &__no_contracts {
//     @include customHeading();
//   }

//   &__row {
//     border-bottom: 0px !important;
//     background-color: var(--base-inner-container-background-color) !important;
//     @include textEllipsis(1);
//     &__artistname {
//       @include customHeading(24px);
//       &__href {
//         @include customHeading(24px);
//         text-decoration: none;
//       }
//     }
//     &__date {
//       @include customParagraph(14px);
//       text-decoration: underline;
//     }
//     &__rep_name {
//       @include customParagraph(14px);
//       text-decoration: underline;
//       text-transform: capitalize;
//     }
//     &__status {
//       &__cip {
//         @include customParagraph(14px);
//         color: var(--base-paragraph-color) !important;
//         border-color: transparent !important;
//       }
//     }
//   }
// }

@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  .table {
    // &__col {

    // }
    // overflow-x: scroll;

    &__row {
      &__sosi {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .table {
    &__row {
      &__artistname {
        &__href {
          @include customHeading(20px);
          // margin-left: 20px;
        }
      }
    }
  }
}
