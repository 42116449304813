@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  width: 100%;

  &__form {
    &__main {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 100%;
      gap: 10px;

      &__field_container {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        width: 100%;
        gap: 10px;

        &__label {
          @include customTags();
        }

        // &__input {
        //   color: #000 !important;
        //   border-style: none;
        //   background: #ffffff !important;
        //   outline: none;
        //   padding: 10px 15px;
        //   font-size: 14px;
        //   border-radius: 12px;
        // }
      }

      &__form_action {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        width: 100%;
        &__forgotPasswordText {
          color: red;
          text-decoration: underline;
        }
      }

      &__action {
        @include flexProperties(row, center, center, $fullWidth: true);
        width: calc(100%);
        &__reset_btn {
          color: var(--base-tags-color) !important;
        }

        &__btn {
          @include activeThemeBotton();
          width: 100%;
        }

        &__register_btn {
          @include activeThemeBotton();
        }
      }

      &__dnthaveaccount {
        @include customParagraph(12px);
        font-weight: 400 !important;
        &__spantwo {
          text-decoration: none;
          padding-left: 10px;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__social_media {
    margin-top: 20px;

    &__button {
      padding: 5px;
      height: 100%;
      background: "#FFFFFF";
      width: 100%;
      cursor: pointer;

      &__img {
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 466px) {
  .page {
    padding: 20px;
  }
}

@media only screen and (max-width: 386px) {
  .page {
    padding: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .page {
    padding: 20px;
    &__logo {
      &__text {
        font-size: 22px !important;
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  .page {
    padding: 20px;
    &__logo {
      &__text {
        font-size: 18px !important;
      }
    }
  }
}

@media only screen and (max-width: 326px) {
  .page {
    padding: 20px;
    &__logo {
      gap: 10px;
      margin-bottom: 10px;
      &__img {
        height: 80px;
      }
      &__text {
        font-size: 16px !important;
      }
    }
  }
}
