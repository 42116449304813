@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__banner {
    background: var(--base-container-backgroung-color) !important;
    width: 100%;
    min-height: 21.25rem;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px;
    border: 1px solid var(--base-border-line-color);

    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      &__insights_title {
        @include customHeading(20px);
      }
      &__refresh_btn {
        @include activeThemeBotton();
        width: 15% !important;
        margin-top: 10px !important;
      }
    }

    &__main_container {
      margin-top: 60px !important;
      &__stack_container {
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &__card_container {
          height: 343px !important;
          width: 204px !important;
          background-color: var(
            --base-inner-container-background-color
          ) !important;
          // flex-basis: 15%;
          flex-shrink: 0;
          border-radius: 12px !important;
          // margin-bottom: 20px !important;
          &:hover {
            .page__banner__button_container {
              display: flex;
            }
          }
          &__card_content {
            &__title {
              @include customHeading(22px);
              color: var(--base-tags-color) !important;
              // @include textEllipsis(60px);
              @include textEllipsisLineClamp(1);
            }
            &__description {
              @include customParagraph(12px);
              @include textEllipsisLineClamp(9);
              margin-top: 20px !important;
            }
          }
        }
      }
    }

    &__button_container {
      background-color: #171d23b7;
      width: calc(100%) !important;
      height: calc(220px) !important;
      margin-top: 123px !important;
      top: 0;
      left: 0;
      position: absolute;
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 2;
      &__btn {
        @include activeThemeBotton();
        width: 70%;
      }
    }

    // &__btn_group {
    //     background-color: #1e242e;
    //     padding: 5px;
    //     border-radius: 12px !important;
    //     &__btn {
    //         @include activeThemeBotton();

    //         line-height: 16px !important;
    //         font-weight: 200 !important;
    //         width: auto;
    //         background-color: transparent !important;
    //         border: 0px !important;
    //         white-space: nowrap;

    //         &:hover {
    //             background-color: #0a0909 !important;
    //         }
    //     }
    // }

    // &__main_content {
    //     width: calc(100%);
    // }
  }

  @media only screen and (max-width: 1425px) {
    .page {
      &__statusBar {
        width: 100%;
      }

      &__banner {
        width: 100%;
      }

      &__main_content {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1267px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__details {
              &__box {
                &__title {
                  font-size: 18px;
                }

                &__value {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__details {
              &__box {
                &__title {
                  font-size: 18px;
                }

                &__value {
                  font-size: 16px;
                }
              }
            }
          }
        }
        &__header {
          &__refresh_btn {
            width: 20% !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 944px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__details {
              &__box {
                &__title {
                  font-size: 16px;
                }

                &__value {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .page {
      &__statusBar {
        display: none;
      }

      &__banner {
        &__conatiner {
          flex-direction: column;

          &__image {
            @include flexProperties(row, center, center, $fullWidth: false);
            width: 100%;
          }

          &__content {
            @include flexProperties(column, center, center, $fullWidth: false);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 677px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__details {
              &__box {
                &__title {
                  font-size: 18px;
                }

                &__value {
                  font-size: 16px;
                }
              }
            }
          }
        }
        &__header {
          &__refresh_btn {
            width: 40% !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 582px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 30px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 559px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 28px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 559px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 26px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 24px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 410px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 22px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 388px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 18px !important;
              letter-spacing: 3px;
            }

            &__details {
              &__box {
                &__title {
                  font-size: 16px;
                }

                &__value {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 18px !important;
              letter-spacing: 2px;
            }

            &__details {
              &__box {
                &__title {
                  font-size: 12px;
                }

                &__value {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 318px) {
    .page {
      &__banner {
        &__conatiner {
          &__content {
            &__main_heading {
              font-size: 16px !important;
              letter-spacing: 0px;
            }

            &__details {
              &__box {
                &__title {
                  font-size: 10px;
                }

                &__value {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
