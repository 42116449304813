@import "../../styles/scss.scss";
@import "../../styles/colors.scss";
// .table {
//   background: #222c41 !important;

//   &__col {
//     color: #4ffcb7 !important;
//     background: #222c41 !important;
//     text-align: left !important;
//     white-space: nowrap;
//     border-bottom: 1px solid #5a7380 !important;
//     text-transform: uppercase;
//     font-size: 12px !important;
//   }

//   &__row {
//     color: var(--base-heading-color) !important;
//     text-align: left !important;
//     max-width: 100% !important;
//     text-transform: capitalize;
//     &__avatar {
//       width: 45px;
//       height: 45px;
//       border-radius: 50%;
//     }
//   }
// }
.page {
  margin-top: 20px;
  font-family: "DM Sans" !important;
  &__divider {
    border-top: 1px solid var(--base-border-line-color) !important;
    height: 2px !important;
    margin-top: 20px !important;
  }
  &__action {
    &__edit_btn {
      background-color: var(--base-action-icon-background) !important;
      width: 33px !important;
      height: 33px !important;
      svg {
        fill: var(--base-action-icon-color) !important;
      }
      &:hover {
        background-color: var(--base-action-icon-background) !important;
        color: var(--base-action-icon-color) !important;
      }
    }
    &__delete_btn {
      background-color: var(--base-action-icon-delete-background) !important;
      width: 33px !important;
      height: 33px !important;
      svg {
        fill: var(--base-action-icon-color) !important;
      }
      &:hover {
        background-color: var(--base-action-icon-delete-background) !important;
        color: var(--base-action-icon-color) !important;
      }
    }
  }
  &__avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  &__type_name {
    @include customHeading(15px);
    @include textEllipsis(150px);
  }
  &__new_music {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding: 0px 20px;
    &__main {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      margin-top: 20px;
      gap: 20px;
      padding: 0px 20px;
      &__heading {
        color: var(--base-tags-color) !important;
        font-size: 12px !important;
        padding-bottom: 10px !important;
      }
      &__select_icon {
        color: var(--base-icon-color) !important;
        font-size: 20px;
        font-weight: bold;
        margin-right: 15px;
        margin-bottom: 5px;
        cursor: pointer;
      }
      &__form {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        gap: 30px;

        &__form_fields {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          flex-wrap: wrap;
          gap: 20px;

          &__input {
            border-bottom: 1px solid #fff;
            border-top: none !important;
            border-left: none;
            border-right: none;
            font-family: "DM Sans" !important;
            outline: none !important;
            background: transparent;
            padding: 5px 0px;
            color: var(--base-input-text-color) !important;
            background-color: transparent;
            width: 110px;
            font-size: 16px;
            font-weight: bold;
            &::placeholder {
              color: var(--base-input-text-color);
              font-weight: bold;
            }
            &:focus {
              border-bottom: 1px solid var(--base-input-focus-color);
            }
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        &__add_button {
          background-color: var(--base-button-background-color) !important;
          color: var(--base-button-text-color) !important;
          border-radius: 12px !important;
          font-size: 14px !important;
          gap: 1px;
          cursor: pointer;
          font-weight: bold !important;
          padding: 7px 16px !important;

          &:hover {
            background: var(--base-button-background-color) !important;
          }
        }
        &__cancel_button {
          background-color: var(
            --base-action-icon-delete-background
          ) !important;
          color: var(--base-button-text-color) !important;
          border-radius: 12px !important;
          font-size: 14px !important;
          gap: 1px;
          cursor: pointer;
          font-weight: bold !important;
          padding: 7px 16px !important;
          &:hover {
            background: var(--base-action-icon-delete-background) !important;
          }
        }
      }
    }
  }

  &__music_container {
    // margin-top: 20px;
    // width: 100%;

    &__button_container {
      // @include flexProperties(row, flex-start, center, $fullWidth: true);
      // gap: 30px;
      width: 100%;

      &__button {
        width: 100% !important;
        color: #222c41 !important;
        // background-color: #498E72 !important;
        border-radius: 12px !important;
        white-space: nowrap !important;
        padding: 8px 50px !important;
        font-size: 14px !important;
        font-weight: bold !important;
      }

      &__slider_box {
        @include flexProperties(
          column,
          flex-start,
          flex-start,
          $fullWidth: true
        );
        width: 100%;

        &__title {
          color: #4ffcb7 !important;
        }

        &__slider {
          @include flexProperties(row, flex-start, center, $fullWidth: true);
          gap: 20px;
          width: 100%;

          &__text {
            color: #4ffcb7;

            width: 100px;
          }
        }
      }
    }
  }

  &__music_details {
    margin-top: 20px;
    padding: 0px 20px;

    &__main_container {
      @include flexProperties(column, center, flex-end, $fullWidth: false);
      gap: 20px;

      &__box {
        @include flexProperties(row, flex-end, center, $fullWidth: false);
        gap: 10px;

        &__details {
          @include flexProperties(row, center, center, $fullWidth: true);
          gap: 5px;
          border: 2px solid #4ffcb7;
          padding: 8px 30px;
          border-radius: 8px;
          min-width: 200px;
          flex-wrap: wrap;

          &__text {
            text-transform: capitalize;
            letter-spacing: 2px;
          }
        }

        &__actions {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          // max-width: 100px;
          width: 90px;
        }
      }
    }
  }
}
.buttonDisabled {
  background-color: var(--base-deactive-button-background-color) !important;
  &:hover {
    background-color: var(--base-deactive-button-background-color);
  }
}
.buttonActive {
  background-color: var(--base-button-background-color) !important;
  &:hover {
    background-color: var(--base-button-background-color) !important;
  }
}

// @media only screen and (max-width: 467px) {
//   .page {
//     &__music_container {
//       &__button_container {
//         @include flexProperties(
//           column,
//           flex-start,
//           flex-start,
//           $fullWidth: true
//         );

//         &__button {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 430px) {
//   .page {
//     &__new_music {
//       &__main {
//         &__form {
//           width: 100%;

//           &__form_fields {
//             @include flexProperties(
//               column,
//               flex-start,
//               flex-start,
//               $fullWidth: true
//             );
//             width: 100%;

//             &__input {
//               width: 100%;
//             }

//             &__select {
//               width: 100%;
//             }
//           }

//           &__button {
//             width: 100%;
//           }
//           &__button2 {
//             width: 100%;
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 600px) {
  .page {
    margin-top: 20px;

    &__new_music {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}
