@mixin flexProperties(
  $direction: row,
  $justify: center,
  $align: center,
  $fullWidth: false
) {
  display: flex;

  @if $fullWidth {
    flex: 1;
  }

  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin customTags() {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: var(--base-tags-color) !important;
  font-family: "DM Sans";
  text-transform: uppercase;
  white-space: nowrap;
}

@mixin customParagraph($pixels: 12px) {
  font-size: $pixels !important;
  font-weight: 400;
  color: var(--base-paragraph-color);
  font-family: "DM Sans";
}

@mixin customHeading($pixels: 28px) {
  font-size: $pixels !important;
  font-weight: 700 !important;
  color: var(--base-heading-color);
  font-family: "DM Sans";
  text-transform: capitalize;
}

@mixin customValue($pixels: 24px) {
  font-size: $pixels !important;
  font-weight: bold !important;
  color: var(--base-paragraph-color);
  font-family: "DM Sans";
  // text-transform: capitalize;
}

@mixin textEllipsis($width: 100px) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}

@mixin textEllipsisLineClamp($clamp: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin activeThemeBotton() {
  background: var(--base-button-background-color) !important;
  color: var(--base-button-text-color) !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  cursor: pointer;
  text-transform: capitalize !important;
  width: 100%;
  border: none !important;
  white-space: nowrap;
  // height: 40px !important;
}

@mixin deActiveThemeBotton($pixels: 14px, $radius: 12px) {
  background: #5a7380 !important;
  color: var(--base-button-deactive-text-color) !important;
  border-radius: $radius !important;
  font-size: $pixels;
  cursor: pointer;
  text-transform: capitalize !important;
  width: 100%;
  font-weight: 700 !important;
  padding: 10px 10px !important;
  border: 1px solid #5a7380 !important;
  height: 40px !important;
}

@mixin cancelDeactiveBtn($pixels: 16px, $radius: 12px) {
  background: transparent !important;
  color: #ff0000 !important;
  border-radius: $radius !important;
  font-size: $pixels;
  cursor: pointer;
  text-transform: capitalize !important;
  width: 100%;
  padding: 10px 10px !important;
  border: 2px solid #ff0000 !important;
  height: 40px !important;

  &:hover {
    // background: #ff0000 !important;
    // color: #000 !important;
  }
}

@mixin cancelThemeButton($pixels: 16px, $radius: 12px) {
  background: #5a7380 !important;
  color: #ffffff !important;
  border-radius: $radius !important;
  font-size: $pixels;
  font-family: "DM Sans";
  font-weight: 700;
  cursor: pointer;
  text-transform: capitalize;
  width: 100%;
  border: none;
  padding: 10px 30px !important;
  height: 40px !important;
}
