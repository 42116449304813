@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  // @include flexProperties(row, flex-start, flex-start, $fullWidth: true);

  &__tabs_container {
    background-color: var(--base-container-backgroung-color) !important;
    border-radius: 12px;
    border: 1px solid var(--base-border-line-color);
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &__tab {
      min-width: 50% !important;
      font-size: 12px !important;
      font-weight: bold !important;
      min-height: 40px !important;
      padding: 0px !important;
      text-transform: uppercase !important;
      color: var(--base-deactive-button-background-color) !important;

      &__activeTabLinc,
      &__activeTabBuy {
        background-color: var(--base-tab-active-background) !important;
        border-radius: 12px !important;
        color: var(--base-button-text-color) !important;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: var(--base-tab-active-background);
          border-radius: 12px;
          transform: scaleX(0);
          transform-origin: 0% 50%;
        }
      }
      &__activeTabLinc {
        animation: slideInLinc 0.2s ease-in-out;
        @keyframes slideInLinc {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0%);
          }
        }
      }
      &__activeTabBuy {
        animation: slideInBuy 0.2s ease-in-out;

        @keyframes slideInBuy {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }
      }
    }
  }

  // &__tablet_view {
  //   display: none;
  // }
  &__dealSummary {
    margin-top: -183px !important;
  }
}
@media only screen and (max-width: 1550px) {
  .page {
    &__dealSummary {
      margin-top: -125px !important;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .page {
    &__dealSummary {
      margin-top: -119px !important;
    }
  }
}

@media only screen and (max-width: 899px) {
  .page {
    &__dealSummary {
      margin-top: 20px !important;
      margin-bottom: -15px !important;
    }
  }
}
